export enum TokenServices {
  AccountManagementAutoGraderProd = 'accountManagementAutoGraderProd',
  AccountManagementAutomationStg = 'accountManagementAutomationStg',
  AccountManagementAutomationProd = 'accountManagementAutomationProd',
  AccountManagementMonitoringProd = 'accountManagementMonitoringProd',
  AdaAutomationStg = 'adaAutomationStg',
  AdaAutomationProd = 'adaAutomationProd',
}

export enum TokenStage {
  Development = 'dev',
  Staging = 'stg',
  Production = 'prod',
}

export interface TokenCredentials {
  stage: TokenStage;
  clientId: string;
  clientSecret: string;
  scopes?: string;
  resource?: string;
}

export interface TokenInfo {
  token: string;
  expiresAt: string | Date;
  expiresIn?: number;
}

export interface TokenInfoWithExpiration extends TokenInfo {
  expiresIn: number;
}
