import { ListParamsStrict } from './list-params';
import { AssetListScope, AssetType, BasicAsset, BasicUser, InternalAssetCreateRequestBody, ResourceIdObject, ResourceList } from './shared';

export interface Section {
  active: boolean;
  children: BasicAsset[];
  createdAt: string;
  deletedAt: string | null;
  description: string | null;
  id: number;
  name: string;
  owner: BasicUser | null;
  parents: BasicAsset[];
  type: AssetType.Section;
  updatedAt: string;
}

export interface SectionListParams extends Pick<ListParamsStrict, 'attributes' | 'page' | 'limit' | 'order' | 'sort'> {
  id?: number[];
  name?: string;
  scope?: AssetListScope;
}

export type SectionList = ResourceList<Partial<Section>>;

export interface CreateSectionRequestBody extends Pick<InternalAssetCreateRequestBody, 'active' | 'description' | 'name'> {
  children: ResourceIdObject[];
  parents: ResourceIdObject[];
}

export interface UpdateSectionRequestBody extends Partial<CreateSectionRequestBody> {
  deletedAt: null;
}
