/**
 * Overview Guard Service
 *
 * This service redirects a user to their dashboard if they have a valid session
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { AuthApiService } from '../api/auth.service';

/**
 * Class representing the Overview Guard service
 */
@Injectable()
export class OverviewGuard {
  /**
   * Overview Guard service
   * @param appService - Global application service
   * @param authApiService - Auth API service
   * @param router - Angular router
   */
  constructor(private readonly appService: AppService, private readonly authApiService: AuthApiService, private readonly router: Router) {}

  /**
   * Check if user has a valid session
   * @param route - Activate route snapshot
   * @param state - Router state snapshot
   * @returns If route is allowed
   */
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const user = await this.authApiService.getSession();
      this.appService.setAccount(user);

      if (route.data.default) {
        this.router.navigate(['account', 'dashboard']);
      }
    } catch (error) {}

    return true;
  }
}
