export enum AssetFilterType {
  MultiSelect = 'multi-select',
}

export enum AssetFilterOperator {
  Eq = 'eq',
  Like = 'like',
  Neq = 'neq',
}

export interface AssetFilterOption {
  label: string;
  value: string;
  operator: AssetFilterOperator;
}

export interface AssetFilter<AssetType> {
  type: AssetFilterType;
  label: string;
  attribute: keyof AssetType;
  valuePath?: string[];
  options: AssetFilterOption[];
}
