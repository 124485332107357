import { AssetAccessCreateUpdate } from '.';
import { ListParamsStrict } from './list-params';
import { Access, AssetType, BasicActivity, BasicTag, BasicUser, InternalAssetCreateRequestBody, ResourceList } from './shared';
import { Values } from './type-utils';

interface BaseLearningPath {
  access: Access;
  coverImage: string | null;
  createdAt: string;
  description: string | null;
  id: number;
  name: string;
  owner: BasicUser | null;
  tags: BasicTag[];
  thumbnail: string | null;
  type: AssetType.LearningPath;
  updatedAt: string;
}

export interface LearningPath extends BaseLearningPath {
  favorite: boolean;
  new: boolean;
  sections: LearningPathSection[];
}

export interface InternalLearningPath extends BaseLearningPath {
  active: boolean;
  completionPercent: number;
  deletedAt: string | null;
  label: string | null;
  sections: InternalLearningPathSection[];
}

export interface LearningPathSection {
  content: LearningPathContent[];
  description: string | null;
  id: number;
  name: string;
  type: AssetType.Section;
}

export interface LearningPathContent {
  access: Access;
  activity: BasicActivity | null;
  favorite: boolean;
  id: number;
  name: string;
  new: boolean;
  selectedModules: LearningPathContentSectionModules[] | null;
  selectedModuleCount: number;
  skillLevel: string;
  thumbnail: string | null;
  type: AssetType.Course | AssetType.Series;
}

export interface InternalLearningPathSection extends Omit<LearningPathSection, 'content'> {
  active: boolean;
  content: InternalLearningPathContent[];
}

export type InternalLearningPathContent = Omit<LearningPathContent, 'activity' | 'favorite' | 'new'>;

export interface LearningPathContentSectionModules {
  sectionId: number;
  modules?: { id: number }[];
}

type BasicLearningPathCommonProperties = 'access' | 'favorite' | 'id' | 'name' | 'new' | 'thumbnail' | 'type';
export type BasicLearningPath = Pick<LearningPath, BasicLearningPathCommonProperties>;

export interface LearningPathListParamsStrict extends Omit<ListParamsStrict, 'all' | 'archive'> {
  scope?: LearningPathListScope;
  name?: string;
}

export const LearningPathListScope = {
  All: 'all',
  Favorited: 'favorited',
  Owned: 'owned',
} as const;
export type LearningPathListScope = Values<typeof LearningPathListScope>;

export type LearningPathList = ResourceList<Partial<BasicLearningPath>>;

export interface LearningPathGetParams {
  learningPathId?: number;
}

export interface CreateLearningPathRequestBody {
  access: AssetAccessCreateUpdate;
  coverImage?: string | null;
  description?: string | null;
  name: string;
  thumbnail?: string | null;
  sections: NewLearningPathSection[];
}
export interface UpdateLearningPathRequestBody extends Partial<Omit<CreateLearningPathRequestBody, 'sections'>> {
  sections?: (NewLearningPathSection | UpdatedLearningPathSection)[];
}

export interface NewLearningPathSection {
  content: {
    id: number;
    selectedModules?: {
      sectionId: number;
      modules?: {
        id: number;
      }[];
    }[];
  }[];
  name: string;
  description?: string;
}

export interface UpdatedLearningPathSection extends NewLearningPathSection {
  id: number;
}

export interface InternalCreateLearningPathRequestBody
  extends Pick<InternalAssetCreateRequestBody, 'access' | 'active' | 'completionPercent' | 'description' | 'label' | 'name' | 'tags'> {
  sections: NewInternalLearningPathSection[];
}

export interface InternalUpdateLearningPathRequestBody extends Partial<Omit<InternalCreateLearningPathRequestBody, 'sections'>> {
  coverImage?: string | null;
  sections?: (NewInternalLearningPathSection | UpdatedInternalLearningPathSection)[];
  thumbnail?: string | null;
}

export interface NewInternalLearningPathSection extends NewLearningPathSection {
  active: boolean;
}

export interface UpdatedInternalLearningPathSection extends NewInternalLearningPathSection {
  id: number;
}
