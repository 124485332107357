import { AssetTypeFilter } from './asset-type-filter';
import { ListOrder, ListParams } from './list-params';
import { Asset, AssetSkillLevel, AssetType } from './shared';

export enum ContentExplorerSortOptions {
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export interface ContentExplorerListParams extends Omit<ListParams, 'all' | 'attributes' | 'archive'> {
  name?: string;
  assetTypes?: string[];
  tags?: string[];
  skillLevel?: AssetSkillLevel[];
  order?: ListOrder;
  sort?: ContentExplorerSortOptions;
}

export interface ContentExplorerList {
  count: number;
  rows: ContentExplorerAsset[];
  filters: ContentExplorerFilter;
}

export interface ContentExplorerAsset extends Omit<Asset, 'createdAt' | 'updatedAt' | 'deletedAt'> {
  readonly id: number | null;
  type: AssetType | null;
  name: string | null;
  label: string | null;
  description: string | null;
  prerequisites: string | null;
  objectives: string | null;
  language: string | null;
  skillLevel: string | AssetSkillLevel | null;
  src: string | null;
  thumbnail: string | null;
  coverImage: any | null;
  duration: number | null;
  active: boolean | null;
  restrict: boolean | null;
  data: string | any | null;
  ownerId: number | null;
  likeCount: number | null;
  viewCount: number | null;
  completionPercent: number;
  readonly createdAt: Date | null;
  readonly updatedAt: Date | null;
  readonly deletedAt: Date | null;
}

export interface ContentExplorerFilter {
  skillLevel: ContentExplorerSkillLevelFilter[];
  assetType: (AssetTypeFilter & { count: number })[];
  tag: ContentExplorerTagFilter[];
}

export interface ContentExplorerSkillLevelFilter {
  skillLevel: AssetSkillLevel;
  count: number;
}

export interface ContentExplorerAssetTypeFilter extends AssetTypeFilter {
  count: number;
}

export interface ContentExplorerTagFilter {
  id: number;
  name: string;
  sort: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  tags: ContentExplorerTag[];
}

export interface ContentExplorerTag {
  name: string;
  count: number;
}
