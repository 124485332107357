import { Activity, StrictBasicUser } from './shared';

export interface LearningPathActivityReport extends ActivityReport {
  user: StrictBasicUser;
}

export interface ActivityReport {
  activity: Activity;
  children: ActivityReport[];
  duration: number;
  id: number;
  name: string;
  moduleCompletedCount: number;
  moduleCount: number;
  moduleSelectedCount: number;
  type: string;
  thumbnail: string;
}

export interface CEUSummary {
  total: number;
  ytd: number;
}
