/**
 * XSRF Interceptor
 *
 * This service contains an interceptor that adds an XSRF header to all
 * supported DTU API requests
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

/**
 * Class representing the XSRF Interceptor service
 */
@Injectable()
export class XSRFInterceptor implements HttpInterceptor {
  /**
   * Intercept HTTP requests and add the XSRF header when appropriate
   * @param request - HTTP request
   * @param next - Next function
   * @returns The updated request
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Add XSRF header to DTU API requests
    if (request.url.startsWith(environment.api) && !['HEAD', 'GET'].includes(request.method)) {
      // Get XSRF token
      const xsrfToken = this.getCookie('dtu_xsrf_token');

      // Set header if XSRF token is available
      if (xsrfToken) {
        request = request.clone({ headers: request.headers.set('X-XSRF-TOKEN', xsrfToken) });
      }
    }

    // Return request
    return next.handle(request);
  }

  /**
   * Get cookie value
   * @param cookieName - Name of cookie to get
   * @returns Cookie value
   */
  getCookie(cookieName: string): string {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${cookieName}=`);
    return parts.length === 2 ? parts.pop().split(';').shift() : null;
  }
}
