import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { NotificationApiService } from '../shared/api/notifications.service';
import { UserApiService } from '../shared/api/users.service';
import { GlobalBannerComponent } from './global-banner/global-banner.component';
import { GlobalNavComponent } from './global-nav/global-nav.component';
import { NotificationMenuComponent } from './global-nav/notification-menu/notification-menu.component';

/**
 * Class representing the Core module
 */
@NgModule({
  declarations: [GlobalNavComponent, GlobalBannerComponent, NotificationMenuComponent],
  exports: [GlobalNavComponent, GlobalBannerComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule, MatSnackBarModule, MatTooltipModule, RouterModule],
  providers: [NotificationApiService, UserApiService],
})
export class CoreModule {}
