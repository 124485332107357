import { ListParamsStrict } from './list-params';
import { AssetListScope, ResourceIdObject, ResourceList } from './shared';
import { Values } from './type-utils';

export interface BaseFaqItem {
  answer: string;
  createdAt: string;
  id: number;
  question: string;
  updatedAt: string;
}

export interface FaqItem extends BaseFaqItem {}

export interface InternalFaqItem extends BaseFaqItem {
  deletedAt: string | null;
}

export interface InternalCreateFaqItemRequestBody extends Pick<InternalFaqItem, 'answer' | 'question'> {}

export type FaqItemList = ResourceList<FaqItem>;
export type InternalFaqItemList = ResourceList<Partial<InternalFaqItem>>;

export interface InternalUpdateFaqItemRequestBody extends Partial<InternalCreateFaqItemRequestBody> {
  deletedAt?: string;
}

export const FaqItemListScope = {
  All: AssetListScope.All,
  Archived: AssetListScope.Archived,
} as const;
export type FaqItemListScope = Values<typeof FaqItemListScope>;

export interface InternalListFaqItemsParams extends Omit<ListParamsStrict, 'all' | 'archive'> {
  answer?: string;
  id?: number[];
  question?: string;
  scope?: FaqItemListScope;
}

export interface BaseFaqSection {
  createdAt: string;
  id: number;
  name: string;
  sort: number;
  updatedAt: string;
}

export interface FaqSection extends BaseFaqSection {}

export interface InternalFaqSection extends BaseFaqSection {
  items: InternalFaqItem[];
  deletedAt?: string | null;
}

export interface InternalCreateFaqSectionRequestBody extends Pick<InternalFaqSection, 'name'> {
  items?: ResourceIdObject[];
}

export type FaqSectionList = ResourceList<FaqSection>;
export type InternalFaqSectionList = ResourceList<Partial<InternalFaqSection>>;

export interface InternalUpdateFaqSectionRequestBody extends Partial<InternalCreateFaqSectionRequestBody> {
  deletedAt?: string;
}

export const FaqSectionListScope = {
  All: AssetListScope.All,
  Archived: AssetListScope.Archived,
} as const;
export type FaqSectionListScope = Values<typeof FaqSectionListScope>;

export interface InternalListFaqSectionsParams extends Omit<ListParamsStrict, 'all' | 'archive'> {
  id?: number[];
  name?: string;
  scope?: FaqSectionListScope;
}

export interface InternalFaqList {
  sections: InternalFaqSection[];
}

export interface FaqList {
  sections: Omit<FaqSection, 'id' | 'sort' | 'createdAt' | 'updatedAt'>[];
}

export interface InternalUpdateFaqRequestBody {
  sections: ResourceIdObject[];
}
