import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, UserList, UserListParams } from '@dtu/types';
import { environment } from '../../../environments/environment';

/**
 * Class representing the User API Service
 */
@Injectable()
export class UserApiService {
  /**
   * User API Service
   * @param http - HTTP Client
   */
  constructor(private readonly http: HttpClient) {}

  /**
   * List users
   * @param params - User attributes and pagination options to filter list on
   * @returns A list of users
   */
  listUsers(params: UserListParams): Promise<UserList> {
    let urlParams = new HttpParams();
    if (params.archive) {
      urlParams = urlParams.set('archive', params.archive.toString());
    }
    if (params.page) {
      urlParams = urlParams.set('page', params.page.toString());
    }
    if (params.limit) {
      urlParams = urlParams.set('limit', params.limit.toString());
    }
    if (params.order) {
      urlParams = urlParams.set('order', params.order);
    }
    if (params.sort) {
      urlParams = urlParams.set('sort', params.sort);
    }
    if (params.attributes) {
      urlParams = urlParams.set('attributes', params.attributes);
    }
    if (params.id) {
      urlParams = urlParams.set('id', params.id.toString());
    }
    if (params.type) {
      urlParams = urlParams.set('type', params.type);
    }
    if (params.email) {
      urlParams = urlParams.set('email', params.email);
    }
    if (params.firstName) {
      urlParams = urlParams.set('firstName', params.firstName);
    }
    if (params.lastName) {
      urlParams = urlParams.set('lastName', params.lastName);
    }
    if (params.company) {
      urlParams = urlParams.set('company', params.company);
    }
    if (params.title) {
      urlParams = urlParams.set('title', params.title);
    }
    if (params.sfdcId) {
      urlParams = urlParams.set('sfdcId', params.sfdcId);
    }

    return this.http.get<UserList>(`${environment.api}/users`, { params: urlParams, withCredentials: true }).toPromise();
  }

  /**
   * Create a new user
   * @param userAttributes - User attributes to create
   * @returns The created user
   */
  createUser(userAttributes: User): Promise<User> {
    return this.http.post<User>(`${environment.api}/users`, userAttributes, { withCredentials: true }).toPromise();
  }

  /**
   * Get an existing user
   * @param userId - Numeric ID of the user to get
   * @returns The requested user
   */
  getUser(userId: number): Promise<User> {
    return this.http.get<User>(`${environment.api}/users/${userId}`, { withCredentials: true }).toPromise();
  }

  /**
   * Merge two existing users
   * @param sourceUserId - Numeric ID of source user to merge
   * @param targetUserId - Numeric ID of target user in merge
   */
  mergeUsers(sourceUserId: number, targetUserId: number): Promise<void> {
    return this.http.post<void>(`${environment.api}/users/merge`, { sourceUserId, targetUserId }, { withCredentials: true }).toPromise();
  }

  /**
   * Update an existing user
   * @param userId - Numeric ID of the user to update
   * @param userAttributes - User attributes to update
   * @returns The updated user
   */
  updateUser(userId: number, userAttributes: User): Promise<User> {
    return this.http.put<User>(`${environment.api}/users/${userId}`, userAttributes, { withCredentials: true }).toPromise();
  }

  /**
   * Delete an existing user
   * @param userId - Numeric ID of the user to delete
   * @returns The deleted user
   */
  deleteUser(userId: number): Promise<User> {
    return this.http.delete<User>(`${environment.api}/users/${userId}`, { withCredentials: true }).toPromise();
  }

  /**
   * List users with at least one reporting role assigned
   * @param params - User attributes and pagination options to filter list on
   * @returns A list of reporting users
   */
  listReportingUsers(params: UserListParams): Promise<UserList> {
    let urlParams = new HttpParams();
    if (params.archive) {
      urlParams = urlParams.set('archive', params.archive.toString());
    }
    if (params.page) {
      urlParams = urlParams.set('page', params.page.toString());
    }
    if (params.limit) {
      urlParams = urlParams.set('limit', params.limit.toString());
    }
    if (params.order) {
      urlParams = urlParams.set('order', params.order);
    }
    if (params.sort) {
      urlParams = urlParams.set('sort', params.sort);
    }
    if (params.attributes) {
      urlParams = urlParams.set('attributes', params.attributes);
    }
    if (params.id) {
      urlParams = urlParams.set('id', params.id.toString());
    }
    if (params.type) {
      urlParams = urlParams.set('type', params.type);
    }
    if (params.email) {
      urlParams = urlParams.set('email', params.email);
    }
    if (params.firstName) {
      urlParams = urlParams.set('firstName', params.firstName);
    }
    if (params.lastName) {
      urlParams = urlParams.set('lastName', params.lastName);
    }
    if (params.company) {
      urlParams = urlParams.set('company', params.company);
    }
    if (params.title) {
      urlParams = urlParams.set('title', params.title);
    }
    if (params.sfdcId) {
      urlParams = urlParams.set('sfdcId', params.sfdcId);
    }

    return this.http.get<UserList>(`${environment.api}/users/reporters`, { params: urlParams, withCredentials: true }).toPromise();
  }

  /**
   * Assume user access
   * @param userId - Numeric ID of the user to assume
   * @returns The requested user
   */
  assumeUserAccess(userId: number): Promise<User> {
    return this.http.post<User>(`${environment.api}/users/${userId}/access/assume`, null, { withCredentials: true }).toPromise();
  }

  /**
   * Reset user access
   * @param userId - Numeric ID of the user to assume
   * @returns The requested user
   */
  resetUserAccess(userId: number): Promise<User> {
    return this.http.post<User>(`${environment.api}/users/${userId}/access/reset`, null, { withCredentials: true }).toPromise();
  }
}
