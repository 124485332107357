import { DatePipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ExportColumn, Order } from '@dtu/types';
import { AppService } from '../../app.service';
import { StripHTMLPipe } from '../../shared/assets/pipes/strip-html.pipe';
import { VideoDurationPipe } from '../../shared/assets/pipes/video-duration.pipe';
import { AdminComponent, AdminPreferences } from '../../shared/types';
import { FiscalYearPipe } from './pipes/fiscal-year.pipe';

interface TableDateFilter {
  name: string;
  value: string;
  startTime: string;
  endTime: string;
}

/**
 * Class representing the Admin shared service
 */
@Injectable()
export class AdminSharedService {
  private _preferencesKey = 'dtu-admin-preferences';
  private _preferences: AdminPreferences;

  editorModules = {
    toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['code-block'], ['link', 'image'], ['clean']],
  };

  timezoneOptions: { name: string; value: string }[];

  // Initialize admin component groups
  // Note: When adding a child route (ex. Test Questions is a child of Tests), the child must be above
  // the parent in order for the Recently Used feature of the admin console to work properly
  adminComponentGroups: { accessPermitted: boolean; components: AdminComponent[] }[] = [
    {
      accessPermitted: this.appService.account.permissions.accessViewer,
      components: [
        { name: 'Roles', path: '/admin/roles', icon: 'security' },
        { name: 'Teams', path: '/admin/teams', icon: 'people' },
        { name: 'Users', path: '/admin/users', icon: 'person' },
      ],
    },
    {
      accessPermitted: this.appService.account.permissions.contentAdmin,
      components: [
        { name: 'Content Explorer Control', path: '/admin/content-explorer', icon: 'dataset' },
        { name: 'Test attempts', path: '/admin/tests/attempts', icon: 'assessment' },
        { name: 'Test categories', path: '/admin/tests/categories', icon: 'label' },
        { name: 'Test questions', path: '/admin/tests/questions', icon: 'question_answer' },
        { name: 'Test reports', path: '/admin/tests/reports', icon: 'data_usage' },
        { name: 'Tests', path: '/admin/tests', icon: 'assignment' },
      ],
    },
    {
      accessPermitted: this.appService.account.permissions.contentEditor,
      components: [
        { name: 'Categories', path: '/admin/categories', icon: 'layers' },
        { name: 'Certification series', path: '/admin/certification-series', icon: 'library_books' },
        { name: 'Certifications', path: '/admin/certifications', icon: 'school' },
        { name: 'Courses', path: '/admin/courses', icon: 'class' },
        { name: 'Content audit', path: '/admin/content-audit', icon: 'task_alt' },
        { name: 'Exams', path: '/admin/exams', icon: 'question_answer' },
        { name: 'Files', path: '/admin/files', icon: 'attach_file' },
        { name: 'Learning paths', path: '/admin/learning-paths', icon: 'playlist_play' },
        { name: 'Quizzes', path: '/admin/quizzes', icon: 'question_answer' },
        { name: 'Sections', path: '/admin/sections', icon: 'view_list' },
        { name: 'URLs', path: '/admin/urls', icon: 'link' },
        { name: 'Series', path: '/admin/series', icon: 'video_library' },
        { name: 'Tags', path: '/admin/tags', icon: 'label' },
        { name: 'Videos', path: '/admin/videos', icon: 'smart_display' },
      ],
    },
    {
      accessPermitted: this.appService.account.permissions.contentEditor || this.appService.account.permissions.labViewer,
      components: [{ name: 'Lab guides', path: '/admin/lab-guides', icon: 'web' }],
    },
    {
      accessPermitted: this.appService.account.permissions.eventViewer,
      components: [
        { name: 'Events', path: '/admin/events', icon: 'date_range' },
        { name: 'Registrations', path: '/admin/registrations', icon: 'event_seat' },
        { name: 'Training', path: '/admin/training', icon: 'event' },
        { name: 'Training Series', path: '/admin/training-series', icon: 'view_week' },
      ],
    },
    {
      accessPermitted: this.appService.account.permissions.labViewer,
      components: [{ name: 'Environments', path: '/admin/environments', icon: 'computer' }],
    },
    {
      accessPermitted: this.appService.account.permissions.eventEditor || this.appService.account.permissions.financeViewer,
      components: [{ name: 'Orders', path: '/admin/orders', icon: 'receipt' }],
    },
    {
      accessPermitted: this.appService.account.permissions.reportViewer,
      components: [{ name: 'Custom reports', path: '/admin/reports', icon: 'data_usage' }],
    },
    {
      accessPermitted: this.appService.account.permissions.surveyViewer,
      components: [
        { name: 'Survey questions', path: '/admin/surveys/questions', icon: 'question_answer' },
        { name: 'Surveys', path: '/admin/surveys', icon: 'assignment' },
      ],
    },
    {
      accessPermitted: this.appService.account.admin,
      components: [
        { name: 'Activity report', path: '/admin/activity', icon: 'data_usage' },
        { name: 'CEUs report', path: '/admin/ceus', icon: 'military_tech' },
        { name: 'Change Asset ownership ', path: '/admin/change-ownership', icon: 'swap_horiz' },
        { name: 'FAQs', path: '/admin/faqs', icon: 'quiz' },
      ],
    },
  ];

  /**
   * Admin Shared service
   * @param appService - Global application service
   */
  constructor(private readonly appService: AppService) {
    this.preferences = this.validatePreferences(JSON.parse(localStorage.getItem(this._preferencesKey)));
  }

  /**
   * Encode a list of JS objects into a CSV file organized by the provided column definitions
   *
   * @param list - List of items to encode into a CSV file
   * @param columnDefinitions - Definition of column headers
   * @returns The csv file represented as a string
   */
  encodeListAsCsv(list: Record<string, any>[], columnDefinitions: ExportColumn[]): string {
    const datePipe = new DatePipe('en-US');
    const fiscalYearPipe = new FiscalYearPipe();
    const stripHtmlPipe = new StripHTMLPipe();
    const decimalPipe = new DecimalPipe('en-US');
    const durationPipe = new VideoDurationPipe();
    const timestampFormat = 'yyyy-MM-dd HH:mm:ss';

    /**
     * Format a printable value to export in CSV format
     * @param value - Value to export
     * @returns The formatted CSV string
     */
    const makeCsv = (value: any): string => {
      return `"${value}",`;
    };

    /**
     * Simple format for creating a CSV string where no data manipulation is needed
     * @param object - Object that contains the value to export
     * @param property - Name of property of export
     * @returns The formatted CSV string
     */
    const makeAttributeCsv = (object: any, property: string): string => {
      return makeCsv(object && object[property] ? object[property] : '');
    };

    let csvFile = `${columnDefinitions.map((column) => `"${column.name}"`).join()}\r\n`;

    for (const item of list) {
      let csvRow = '';
      for (const column of columnDefinitions) {
        switch (column.type) {
          case 'approver-attribute':
            csvRow += makeAttributeCsv(item.approver, column.value);
            break;
          case 'asset-attribute':
            csvRow += makeAttributeCsv(item.asset, column.value);
            break;
          case 'boolean':
            csvRow += makeCsv(!!item[column.value]);
            break;
          case 'data-date-time':
            csvRow += makeCsv(
              item.data?.details && item.data.details[column.value]
                ? datePipe.transform(item.data.details[column.value], timestampFormat)
                : '',
            );
            break;
          case 'date-fiscal-year':
            csvRow += makeCsv(item[column.value] ? fiscalYearPipe.transform(item[column.value]) : '');
            break;
          case 'date-time-local':
            csvRow += makeCsv(item[column.value] ? datePipe.transform(item[column.value], timestampFormat) : '');
            break;
          case 'event-attribute':
            csvRow += makeAttributeCsv(item.event, column.value);
            break;
          case 'event-details':
            csvRow += makeAttributeCsv(item.data?.details, column.value);
            break;
          case 'event-date-time':
            csvRow += makeCsv(
              item[column.value]
                ? datePipe.transform(item[column.value], timestampFormat, item.event.data?.details?.timezoneOffset ?? undefined)
                : item.event.data?.details && item.event.data.details[column.value]
                ? datePipe.transform(
                    item.event.data.details[column.value],
                    timestampFormat,
                    item.event.data?.details?.timezoneOffset ?? undefined,
                  )
                : '',
            );
            break;
          case 'event-timezone':
            csvRow += makeAttributeCsv(item.data?.details, 'timezone');
            break;
          case 'ceu-report-event-date-time':
            csvRow += makeCsv(
              item[column.value]
                ? datePipe.transform(item.asset.details[column.value], timestampFormat, item.asset.details.timezoneOffset ?? undefined)
                : item.asset.details && item.asset.details[column.value]
                ? datePipe.transform(item.asset.details[column.value], timestampFormat, item.asset.details.timezoneOffset ?? undefined)
                : '',
            );
            break;
          case 'ceu-report-asset-details-attribute':
            csvRow += makeAttributeCsv(item.asset?.details, column.value);
            break;
          case 'ceu-data-attribute':
            csvRow += makeAttributeCsv(item.asset?.ceuData, column.value);
            break;
          case 'user-ceu-attribute':
            csvRow += makeAttributeCsv(item.userCeu, column.value);
            break;
          case 'nested-event-timezone':
            csvRow += makeAttributeCsv(item.event?.data?.details, 'timezone');
            break;
          case 'html':
            csvRow += makeCsv(item[column.value] ? stripHtmlPipe.transform(item[column.value].toString().replace(/["]+/g, "'")) : '');
            break;
          case 'order-registration-event-attribute': {
            const orderHasNoRegistrations = (item as Order).registrations.length === 0;

            if (orderHasNoRegistrations) {
              csvRow += makeCsv('');
            } else {
              csvRow += makeAttributeCsv(item.registrations[0]?.event, column.value);
            }
            break;
          }
          case 'order-registration-event-date-time': {
            const orderHasNoRegistrations = (item as Order).registrations.length === 0;

            let data;
            if (orderHasNoRegistrations) {
              data = '';
            } else {
              data = item.registrations[0]?.event?.data?.details
                ? datePipe.transform(item.registrations[0].event.data.details[column.value], timestampFormat)
                : '';
            }

            csvRow += makeCsv(data);
            break;
          }
          case 'order-registration-event-start-time-custom': {
            const orderHasNoRegistrations = (item as Order).registrations.length === 0;

            if (orderHasNoRegistrations) {
              csvRow += makeCsv('');
            } else {
              const startTime = item?.registrations[0]?.event?.data?.details?.startTime ?? null;
              if (column.value === 'fiscalYear') {
                csvRow += makeCsv(startTime ? fiscalYearPipe.transform(startTime) : '');
              } else {
                csvRow += makeCsv(startTime ? datePipe.transform(startTime, column.value) : '');
              }
            }
            break;
          }
          case 'owner-attribute':
            csvRow += makeAttributeCsv(item.owner, column.value);
            break;
          case 'status':
            csvRow += makeCsv(item[column.value] ? 'Active' : 'Disabled');
            break;
          case 'text':
            csvRow += makeCsv(item[column.value] ? item[column.value].toString().replace(/["]+/g, "'") : '');
            break;
          case 'training-event-date-time':
            csvRow += makeCsv(datePipe.transform(item[column.value], timestampFormat, item.timezoneOffset));
            break;
          case 'user-attribute':
            csvRow += makeAttributeCsv(item.user, column.value);
            break;
          case 'short-date':
            csvRow += makeCsv(item[column.value] ? datePipe.transform(item[column.value], 'shortDate') : '');
            break;
          case 'multiple-list-items-boolean':
            csvRow += makeCsv(item[column.value].length > 1);
            break;
          case 'decimal':
            csvRow += makeCsv(decimalPipe.transform(item[column.value], '1.0-2'));
            break;
          case 'duration':
            csvRow += makeCsv(durationPipe.transform(item[column.value]));
            break;
          default:
            throw new Error(`Invalid column type: ${column.type}`);
        }
      }
      csvFile += `${csvRow.slice(0, -1)}\r\n`;
    }

    return csvFile;
  }

  /**
   * Generate a string representing the current date, formatted to be included within a filename
   *
   * @returns The formatted date string
   */
  generateDateForFileName(): string {
    const date = new Date();
    return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
  }

  /**
   * Validate the admin preferences loaded from local storage
   * @param localStoragePreferences - Admin preferences loaded from local storage
   * @returns Verified admin preferences
   */
  validatePreferences(localStoragePreferences: AdminPreferences): AdminPreferences {
    const preferences: AdminPreferences = {};

    preferences.asset = localStoragePreferences?.asset
      ? localStoragePreferences.asset
      : {
          recentlyViewed: { categoryIds: [], sectionIds: [], trainingIds: [] },
        };

    preferences.certification = localStoragePreferences?.certification
      ? localStoragePreferences.certification
      : { recentlyViewedCertificationIds: [] };

    preferences.certificationSeries = localStoragePreferences?.certificationSeries
      ? localStoragePreferences.certificationSeries
      : { recentlyViewedCertificationSeriesIds: [] };

    preferences.course = localStoragePreferences?.course ? localStoragePreferences.course : { recentlyViewedCourseIds: [] };

    preferences.dashboard = localStoragePreferences?.dashboard ? localStoragePreferences.dashboard : { recentlyUsedComponents: [] };

    preferences.environment = localStoragePreferences?.environment
      ? localStoragePreferences.environment
      : { recentlyViewedEnvironmentIds: [] };

    preferences.exam = localStoragePreferences?.exam ? localStoragePreferences.exam : { recentlyViewedExamIds: [] };

    // Since FAQ preferences structure changed after being released, we have to check if the correct structure is in place
    // This allows us to use the new structure of FAQ Sections and Items without having users clear their browser's memory
    preferences.faq = localStoragePreferences?.faq?.recentlyViewedFaqItemIds
      ? localStoragePreferences.faq
      : { recentlyViewedFaqItemIds: [], recentlyViewedFaqSectionIds: [] };

    preferences.file = localStoragePreferences?.file ? localStoragePreferences.file : { recentlyViewedFileIds: [] };

    preferences.report = localStoragePreferences?.report ? localStoragePreferences.report : { recentlyViewedReportIds: [] };

    preferences.labGuide = localStoragePreferences?.labGuide ? localStoragePreferences.labGuide : { recentlyViewedLabGuideIds: [] };

    preferences.learningPath = localStoragePreferences?.learningPath
      ? localStoragePreferences.learningPath
      : { recentlyViewedLearningPathIds: [] };

    preferences.order = localStoragePreferences?.order ? localStoragePreferences.order : { recentlyViewedOrderIds: [] };

    preferences.registration = localStoragePreferences?.registration
      ? localStoragePreferences.registration
      : { recentlyViewedRegistrationIds: [] };

    preferences.survey = localStoragePreferences?.survey
      ? localStoragePreferences.survey
      : { recentlyViewedSurveyIds: [], recentlyViewedSurveyQuestionIds: [] };

    preferences.tag = localStoragePreferences?.tag ? localStoragePreferences.tag : { recentlyViewedTagIds: [] };

    preferences.test = localStoragePreferences?.test
      ? localStoragePreferences.test?.recentlyViewedTestAttemptIds
        ? localStoragePreferences.test
        : { ...localStoragePreferences.test, recentlyViewedTestAttemptIds: [] }
      : { recentlyViewedTestIds: [], recentlyViewedTestQuestionIds: [], recentlyViewedTestAttemptIds: [] };

    const previouslyViewedTrainingIds = localStoragePreferences?.asset?.recentlyViewed.trainingIds ?? [];
    preferences.training = localStoragePreferences?.training?.recentlyViewedTrainingIds.length
      ? localStoragePreferences.training
      : { recentlyViewedTrainingIds: previouslyViewedTrainingIds };

    preferences.trainingEvent = localStoragePreferences?.trainingEvent
      ? localStoragePreferences.trainingEvent
      : { recentlyViewedTrainingEventIds: [] };

    preferences.trainingSeries = localStoragePreferences?.trainingSeries
      ? localStoragePreferences.trainingSeries
      : { recentlyViewedTrainingSeriesIds: [] };

    preferences.url = localStoragePreferences?.url ? localStoragePreferences.url : { recentlyViewedUrlIds: [] };

    preferences.user = localStoragePreferences?.user ? localStoragePreferences.user : { layout: 'tiles', recentlyViewedUserIds: [] };

    preferences.series = localStoragePreferences?.series ? localStoragePreferences.series : { recentlyViewedSeriesIds: [] };

    preferences.video = localStoragePreferences?.video ? localStoragePreferences.video : { recentlyViewedVideoIds: [] };

    return preferences;
  }

  /**
   * Get admin preferences
   */
  get preferences(): AdminPreferences {
    return this._preferences;
  }

  /**
   * Set admin preferences
   * @param preferences - The updated admin preferences
   */
  set preferences(preferences: AdminPreferences) {
    this._preferences = preferences;
    localStorage.setItem(this._preferencesKey, JSON.stringify(preferences));
  }

  /**
   * Generate the last 4 fiscal quarters
   */
  generatePreviousFiscalQuartersFilters(): TableDateFilter[] {
    const fiscalYearPipe = new FiscalYearPipe();
    const fiscalQuarters: TableDateFilter[] = [];

    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);

    let currentQuarter = fiscalYearPipe.transform(currentDate.toISOString());
    while (currentQuarter === fiscalYearPipe.transform(currentDate.toISOString())) {
      currentDate.setDate(currentDate.getDate() + 1);
    }
    currentDate.setDate(currentDate.getDate() - 1);
    let currentQuarterEndDate = new Date(currentDate);
    currentQuarterEndDate.setUTCHours(23, 59, 59, 999);

    while (fiscalQuarters.length < 4) {
      if (currentQuarter !== fiscalYearPipe.transform(currentDate.toISOString())) {
        const currentQuarterStartDate = new Date(currentDate);
        currentQuarterStartDate.setUTCHours(0, 0, 0, 0);
        currentQuarterStartDate.setDate(currentQuarterStartDate.getDate() + 1);

        fiscalQuarters.push({
          name: currentQuarter,
          value: currentQuarter.toLowerCase(),
          startTime: currentQuarterStartDate.toISOString(),
          endTime: currentQuarterEndDate.toISOString(),
        });

        currentQuarter = fiscalYearPipe.transform(currentDate.toISOString());
        currentQuarterEndDate = new Date(currentDate);
        currentQuarterEndDate.setUTCHours(23, 59, 59, 999);
      }
      currentDate.setDate(currentDate.getDate() - 1);
    }

    return fiscalQuarters;
  }

  /**
   * Generate time filter
   * @param timeFrame - Time frame to generate
   */
  generateTimeFilter(timeFrame: string): TableDateFilter {
    const startOfToday = new Date();
    startOfToday.setUTCHours(0, 0, 0, 0);

    const endOfToday = new Date();
    endOfToday.setUTCHours(23, 59, 59, 999);

    // Units of constants are in days
    const ONE_WEEK = 7;
    const ONE_MONTH = 30;
    const THREE_MONTHS = 90;
    const SIX_MONTHS = 180;
    const ONE_YEAR = 365;

    switch (timeFrame) {
      case 'last-week':
        startOfToday.setDate(startOfToday.getDate() - ONE_WEEK);
        return { name: 'Last 7 days', value: 'last-week', startTime: startOfToday.toISOString(), endTime: endOfToday.toISOString() };
      case 'last-month':
        startOfToday.setDate(startOfToday.getDate() - ONE_MONTH);
        return { name: 'Last 30 days', value: 'last-month', startTime: startOfToday.toISOString(), endTime: endOfToday.toISOString() };
      case 'last-three-months':
        startOfToday.setDate(startOfToday.getDate() - THREE_MONTHS);
        return {
          name: 'Last 3 months',
          value: 'last-three-months',
          startTime: startOfToday.toISOString(),
          endTime: endOfToday.toISOString(),
        };
      case 'last-six-months':
        startOfToday.setDate(startOfToday.getDate() - SIX_MONTHS);
        return {
          name: 'Last 6 months',
          value: 'last-six-months',
          startTime: startOfToday.toISOString(),
          endTime: endOfToday.toISOString(),
        };
      case 'last-year':
        startOfToday.setDate(startOfToday.getDate() - ONE_YEAR);
        return {
          name: 'Last year',
          value: 'last-year',
          startTime: startOfToday.toISOString(),
          endTime: endOfToday.toISOString(),
        };
      default: {
        return { name: 'Custom', value: 'custom', startTime: null, endTime: null };
      }
    }
  }
}
