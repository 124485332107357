import { ListParams } from './list-params';
import { ResourceList, User } from './shared';

export interface BaseNotification {
  readonly id?: number;
  delivery?: string;
  deliveryTime?: string;
  user?: User;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

export interface ReminderNotification extends BaseNotification {
  type: NotificationType.Reminder;
  data: ReminderNotificationData | null;
}

export interface CertificationRenewalReminderNotification extends BaseNotification {
  type: NotificationType.CertificationRenewalReminder;
  data: CertificationRenewalReminderNotificationData | null;
}

export type Notification = ReminderNotification | CertificationRenewalReminderNotification;

export enum NotificationType {
  Reminder = 'reminder',
  CertificationRenewalReminder = 'certification-renewal-reminder',
}

export interface ReminderNotificationData {
  title: string;
  message: string;
  registrationId: number;
}

export interface CertificationRenewalReminderNotificationData {
  certificationId: number;
  title: string;
  message: string;
}

export interface NotificationListParams extends ListParams {
  id?: number;
  type?: string;
  delivery?: string;
  deliveryTime?: string;
  userId?: number;
  userEmail?: string;
}

export type NotificationList = ResourceList<Notification>;
