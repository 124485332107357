import { AssetType, BasicAssetActivity, BasicTag, LanguageCode, StrictBasicUser } from './shared';
import { BasicSurvey } from './surveys';

export interface Video {
  activity: BasicAssetActivity;
  completionPercent: number;
  createdAt: string;
  data: Record<string, any>;
  description: string | null;
  duration: number;
  favorite: boolean;
  id: number;
  instructors: StrictBasicUser[];
  language: string;
  name: string;
  objectives: string | null;
  prerequisites: string | null;
  skillLevel: string;
  src: string;
  surveys: Required<BasicSurvey>[];
  tags: Required<BasicTag>[];
  transcript: TranscriptElement[] | null;
  type: AssetType.Video;
  updatedAt: string;
}

export interface TranscriptElement {
  caption: string; // English caption text
  captionTranslations: Record<Partial<LanguageCode>, string>;
  startTime: string;
  endTime: string;
}

export interface CaptionElement {
  endTime: number;
  startTime: number;
  text: string;
}
