export type AnyObject = Record<string, any>;

/**
 * Returns the types of the values of a object. If combined with an object
 * literal declared with "as const", then the types will be narrowed to their
 * specific values.
 */
export type Values<T extends {}> = T[keyof T];

/**
 * Deeply traverses an object type, omitting the property or properties provided.
 */
export type DeepOmit<T extends AnyObject, K extends keyof T> = {
  [P in Exclude<keyof T, K>]: T[P] extends AnyObject[] ? DeepOmit<T[P][number], K>[] : T[P] extends AnyObject ? DeepOmit<T[P], K> : T[P];
};
