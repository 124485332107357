/**
 * Admin Guard Service
 *
 * This service checks to see if the user is an admin before completing route
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppService } from '../../app.service';
import { AuthApiService } from '../api/auth.service';

/**
 * Class representing the Admin Guard service
 */
@Injectable()
export class AdminGuard {
  /**
   * Admin Guard service
   * @param appService - Global application service
   * @param authApiService - Auth API service
   * @param router - Angular router
   */
  constructor(private readonly appService: AppService, private readonly authApiService: AuthApiService, private readonly router: Router) {}

  /**
   * Check if user is an admin before routing
   * @param route - Activate route snapshot
   * @param state - Router state snapshot
   * @returns If route is allowed
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      const user = await this.authApiService.getSession();
      this.appService.setAccount(user);

      const hasAdminRole = this.appService.account.roles.some((role) => this.authApiService.isDtuRole(role.name));

      if (this.appService.account.admin || hasAdminRole) {
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }
    } catch (error) {
      const redirectUrl = `${window.origin}${state.url}`;
      this.authApiService.signIn(redirectUrl);
      return false;
    }
  }

  /**
   * For child routes, check if user is logged in before routing
   * @param route - Activate route snapshot
   * @param state - Router state snapshot
   * @returns If route is allowed
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
