export enum SSORole {
  Contractors = 'contractors',
  Customers = 'customers',
  DynatraceProspect = 'dynatrace-prospect',
  Employees = 'employees',
  Interns = 'interns',
  Partners = 'partners',
}

export enum DtuRole {
  AccessAdmin = 'dtu-access-admin',
  AccessViewer = 'dtu-access-viewer',
  CertificationAdmin = 'dtu-certification-admin',
  CertificationEditor = 'dtu-certification-editor',
  CertificationViewer = 'dtu-certification-viewer',
  ContentAdmin = 'dtu-content-admin',
  ContentEditor = 'dtu-content-editor',
  ContentViewer = 'dtu-content-viewer',
  EventAdmin = 'dtu-event-admin',
  EventEditor = 'dtu-event-editor',
  EventViewer = 'dtu-event-viewer',
  LabAdmin = 'dtu-lab-admin',
  LabEditor = 'dtu-lab-editor',
  LabViewer = 'dtu-lab-viewer',
  FinanceAdmin = 'dtu-finance-admin',
  FinanceViewer = 'dtu-finance-viewer',
  ReportAdmin = 'dtu-report-admin',
  ReportEditor = 'dtu-report-editor',
  ReportViewer = 'dtu-report-viewer',
  SurveyAdmin = 'dtu-survey-admin',
  SurveyEditor = 'dtu-survey-editor',
  SurveyViewer = 'dtu-survey-viewer',
  TeamAdmin = 'dtu-team-admin',
  TeamEditor = 'dtu-team-editor',
  TestAdmin = 'dtu-test-admin',
  TestEditor = 'dtu-test-editor',
  TestViewer = 'dtu-test-viewer',
  PiiViewer = 'dtu-pii-viewer',
}
