export enum AccessRestriction {
  ReadOnly = 'READ_ONLY',
  Restricted = 'RESTRICTED',
}

export enum AccessType {
  Edit = 'EDIT',
  View = 'VIEW',
}

export enum HealthStatus {
  Healthy = 'HEALTHY',
  Pending = 'PENDING',
  Unhealthy = 'UNHEALTHY',
}

export enum ResourceOperation {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

export enum AethersResourceType {
  Account = 'ACCOUNT',
  Blueprint = 'BLUEPRINT',
  BlueprintModule = 'BLUEPRINT_MODULE',
  Environment = 'ENVIRONMENT',
  EnvironmentResource = 'ENVIRONMENT_RESOURCE',
  Module = 'MODULE',
  Provider = 'PROVIDER',
  Template = 'TEMPLATE',
  TemplateModule = 'TEMPLATE_MODULE',
}

export interface ModificationInfo {
  createdTime: Date;
  lastModifiedTime: Date;
}

export interface ModificationHistory {
  actorId: string;
  createdAt: Date;
  data: Record<string, unknown>;
  description: string;
  id: string;
  operation: ResourceOperation;
  resourceId: string;
  resourceType: AethersResourceType;
}

export interface ResourceAccess {
  modificationInfo: ModificationInfo;
  type: AccessType;
  user: AethersUserSummary;
  userId: string;
}

export enum AccountStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export interface Account {
  access: AccountAccessSummary[];
  costCenter: string;
  dtCredentialId: string | null;
  externalCredentialId: string;
  externalId: string;
  externalSupportedRegions: string[];
  externalUrl: string;
  healthStatus: HealthStatus;
  id: string;
  modificationHistory: ModificationHistory[];
  modificationInfo: ModificationInfo;
  name: string;
  owner: AethersUserSummary;
  ownerId: string;
  provider: ProviderSummary;
  providerId: string;
  provisioning: AccountProvisioning | null;
  restriction: AccessRestriction;
  status: AccountStatus;
}

export interface AccountAccess extends ResourceAccess {
  accountId: string;
}

export type AccountAccessSummary = Pick<AccountAccess, 'type' | 'user'>;

export interface AccountProvisioning {
  terraformCreateBuildId: string;
  terraformDeleteBuildId: string;
}

export type AccountSummary = Pick<
  Account,
  'externalSupportedRegions' | 'healthStatus' | 'id' | 'modificationInfo' | 'name' | 'owner' | 'provider' | 'status'
>;

export enum AethersEnvironmentStatus {
  Deprovisioning = 'DEPROVISIONING',
  Failed = 'FAILED',
  Provisioning = 'PROVISIONING',
  Running = 'RUNNING',
  Scheduled = 'SCHEDULED',
  Stopped = 'STOPPED',
}

export interface AethersEnvironment {
  access: EnvironmentAccessSummary[];
  cost: number | null;
  costCenter: string | null;
  endTime: Date;
  healthStatus: HealthStatus;
  id: string;
  modificationHistory: ModificationHistory[];
  modificationInfo: ModificationInfo;
  name: string;
  owner: AethersUserSummary;
  ownerId: string;
  provisioning: EnvironmentProvisioning | null;
  resources: EnvironmentResourceSummary[];
  restriction: AccessRestriction;
  startTime: Date;
  status: AethersEnvironmentStatus;
  template: TemplateSummary;
  templateId: string;
  timezone: string;
}

export interface EnvironmentProvisioning {
  duration: number | null;
  modules: EnvironmentProvisioningModule[];
}

export interface EnvironmentProvisioningModule {
  duration: number | null;
  endTime: string | null;
  id: string;
  logs: EnvironmentProvisioningLog[];
  name: string;
  parameters: EnvironmentProvisioningParameters | null;
  startTime: string | null;
  status: EnvironmentProvisioningStatus;
}

export type EnvironmentProvisioningParameters = Record<string, unknown>;

export interface EnvironmentProvisioningLog {
  label: string;
  logGroupName: string;
  logStreamName: string;
}

export enum EnvironmentProvisioningStatus {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Scheduled = 'SCHEDULED',
  Success = 'SUCCESS',
}

export interface EnvironmentAccess extends ResourceAccess {
  environmentId: string;
}

export type EnvironmentAccessSummary = Pick<EnvironmentAccess, 'type' | 'user'>;

export interface EnvironmentCreatePayload {
  endTime: string;
  name: string;
  startTime: string;
  templateId: string;
  timezone: string;
  costCenter?: string; // TODO - add to Aether
}

export interface EnvironmentUpdatePayload extends EnvironmentCreatePayload {}

export interface EnvironmentResource {
  account: AccountSummary;
  accountId: string;
  environmentId: string;
  iconUrl: string;
  id: string;
  logs: EnvironmentResourceLog[];
  metadata: EnvironmentResourceMetadata[];
  metrics: EnvironmentResourceMetric[];
  modificationInfo: ModificationInfo;
  name: string;
  region: string;
  status: AethersEnvironmentStatus;
  type: string;
}

export interface EnvironmentResourceLog {
  label: string;
  logGroupName: string;
  logStreamName: string;
}

export interface EnvironmentResourceMetadata {
  label: string;
  type: string;
  value: string;
}

export interface EnvironmentResourceMetric {
  label: string;
  query: string;
}

export type EnvironmentResourceSummary = Pick<
  EnvironmentResource,
  'account' | 'iconUrl' | 'id' | 'logs' | 'metadata' | 'metrics' | 'name' | 'region' | 'status' | 'type'
>;

export interface Module {
  access: ModuleAccessSummary[];
  custom: boolean;
  description: string;
  iconUrl: string;
  id: string;
  modificationHistory: ModificationHistory[];
  modificationInfo: ModificationInfo;
  name: string;
  outputs: ModuleOutputs;
  owner: AethersUserSummary;
  ownerId: string;
  parameters: ModuleParameters;
  provider: ProviderSummary;
  providerId: string;
  resources: ModuleResource[];
  restriction: AccessRestriction;
  source: string;
  type: AethersModuleType;
  version: string;
}

export enum AethersModuleType {
  Terraform = 'TERRAFORM',
}

export interface ModuleAccess extends ResourceAccess {
  moduleId: string;
}

export type ModuleAccessSummary = Pick<ModuleAccess, 'type' | 'user'>;

export type ModuleOutputs = Record<string, ModuleSchema>;

export type ModuleParameters = Record<string, ModuleSchema>;

export interface ModuleResource {
  iconUrl: string;
  logs: ModuleResourceLog[];
  metadata: ModuleResourceMetadata[];
  metrics: ModuleResourceMetric[];
  name: string;
  type: string;
}

export interface ModuleResourceLog {
  label: string;
  logGroupName: string;
  logStreamName: string;
}

export interface ModuleResourceMetadata {
  label: string;
  type: string;
  value: string;
}

export interface ModuleResourceMetric {
  label: string;
  query: string;
}

export type ModuleSchema =
  | ModuleArraySchema
  | ModuleBooleanSchema
  | ModuleObjectSchema
  | ModuleNumberSchema
  | ModuleStringSchema
  | ModuleIntegerSchema;

export enum ModuleSchemaType {
  Array = 'array',
  Boolean = 'boolean',
  Object = 'object',
  Number = 'number',
  String = 'string',
  Integer = 'integer',
}

export enum ModuleSchemaFormType {
  Code = 'code',
  JsonConfig = 'json-config',
  KeyValue = 'key-value',
  Number = 'number',
  Select = 'select',
  Text = 'text',
}

export interface ModuleArraySchema extends ModuleBaseSchema {
  type: ModuleSchemaType.Array;
  default?: ModuleObject[];
  value?: ModuleObject[];
}

export interface ModuleBooleanSchema extends ModuleBaseSchema {
  type: ModuleSchemaType.Boolean;
  default?: boolean | ModuleReference;
  value?: boolean | ModuleReference;
}

export interface ModuleObjectSchema extends ModuleBaseSchema {
  type: ModuleSchemaType.Object;
  default?: ModuleObject;
  value?: ModuleObject;
}

export interface ModuleNumberSchema extends ModuleBaseSchema {
  type: ModuleSchemaType.Number;
  default?: number | ModuleReference;
  maximum?: number;
  minimum?: number;
  value?: number | ModuleReference;
}

export interface ModuleStringSchema extends ModuleBaseSchema {
  type: ModuleSchemaType.String;
  default?: string | ModuleReference;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  value?: string | ModuleReference;
}

export interface ModuleIntegerSchema extends ModuleBaseSchema {
  type: ModuleSchemaType.Integer;
  default?: number | ModuleReference;
  maximum?: number;
  minimum?: number;
  value?: number | ModuleReference;
}

export interface ModuleBaseSchema {
  name: string;
  description?: string;
  required?: boolean;
  formCategory?: string;
  formType?: ModuleSchemaFormType;
  options?: ModuleSchemaOption[];
  documentation?: ModuleSchemaDocumentation;
}

export interface ModuleSchemaOption {
  label: string;
  value: string;
}

interface ModuleSchemaDocumentation {
  description: string;
  url?: string;
}

export interface ModuleObject {
  [property: string]: string | ModuleReference;
}

export interface ModuleReference {
  $ref: ModuleReferenceValue;
}

export interface ModuleReferenceValue {
  moduleId: string;
  moduleName: string;
  outputProperty: string;
  outputName: string;
}

export enum ProviderType {
  AWS = 'AWS',
  Azure = 'AZURE',
  Dynatrace = 'DYNATRACE',
  GCP = 'GCP',
}

export interface Provider {
  id: string;
  modificationInfo: ModificationInfo;
  name: string;
  type: ProviderType;
}

export type ProviderSummary = Pick<Provider, 'id' | 'name' | 'type'>;

export interface Template {
  access: TemplateAccessSummary[];
  estimatedCost: number | null;
  healthStatus: HealthStatus;
  id: string;
  modificationHistory: ModificationHistory[];
  modificationInfo: ModificationInfo;
  modules: TemplateModuleSummary[];
  name: string;
  owner: AethersUserSummary;
  ownerId: string;
  provisioning: TemplateProvisioning | null;
  restriction: AccessRestriction;
}

export interface TemplateAccess extends ResourceAccess {
  templateId: string;
}

export type TemplateAccessSummary = Pick<TemplateAccess, 'type' | 'user'>;

export interface TemplateProvisioning {
  deprovisionStepFunctionArn: string;
  provisionStepFunctionArn: string;
}

export type TemplateSummary = Pick<Template, 'estimatedCost' | 'healthStatus' | 'id' | 'modificationInfo' | 'modules' | 'name' | 'owner'>;

export interface TemplateModule extends Omit<Module, 'access' | 'owner' | 'ownerId' | 'restriction'> {
  account?: AccountSummary;
  accountId?: string;
  alias: string;
  moduleId: string;
  region?: string;
  templateId: string;
}

export type TemplateModuleSummary = Pick<
  TemplateModule,
  | 'account'
  | 'alias'
  | 'custom'
  | 'description'
  | 'iconUrl'
  | 'id'
  | 'moduleId'
  | 'name'
  | 'outputs'
  | 'parameters'
  | 'provider'
  | 'region'
  | 'resources'
  | 'source'
  | 'type'
  | 'version'
>;

export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER',
}

export interface AethersUser {
  email: string;
  externalId: string;
  firstName: string;
  id: string;
  lastName: string;
  modificationInfo: ModificationInfo;
  profileImageUrl: string | null;
  role: UserRole;
}

export type AethersUserSummary = Pick<AethersUser, 'id' | 'email' | 'externalId' | 'firstName' | 'lastName' | 'profileImageUrl' | 'role'>;
