import { ListParamsStrict } from './list-params';
import {
  Access,
  AssetListScope,
  AssetSkillLevel,
  AssetType,
  BasicAsset,
  BasicAssetActivity,
  BasicTag,
  InternalAssetCreateRequestBody,
  ResourceIdObject,
  ResourceList,
  StrictBasicUser,
  SupportedLanguage,
} from './shared';

export interface BaseTrainingSeries {
  access: Access;
  coverImage: string | null;
  createdAt: string;
  description: string | null;
  endTime: string;
  id: number;
  language: string | SupportedLanguage;
  location: string | null;
  name: string;
  objectives: string | null;
  prerequisites: string | null;
  schedule: TrainingSeriesSession[];
  skillLevel: AssetSkillLevel;
  startTime: string;
  tags: BasicTag[];
  thumbnail: string | null;
  timezone: string;
  timezoneList: string[];
  timezoneOffset: string;
  type: AssetType.TrainingSeries;
  updatedAt: string;
  virtual: boolean;
}

export interface TrainingSeries extends BaseTrainingSeries {
  activity: BasicAssetActivity;
}

export interface InternalTrainingSeries extends BaseTrainingSeries {
  active: boolean;
  deletedAt: string | null;
  label: string | null;
  owner: StrictBasicUser | null;
  parents: BasicAsset[];
}

export interface TrainingSeriesSession {
  startTime: string;
  endTime: string;
}

export type BaseBasicTrainingSeries = Pick<
  BaseTrainingSeries,
  | 'access'
  | 'createdAt'
  | 'description'
  | 'endTime'
  | 'id'
  | 'language'
  | 'location'
  | 'name'
  | 'skillLevel'
  | 'startTime'
  | 'thumbnail'
  | 'type'
  | 'updatedAt'
  | 'virtual'
>;

export interface InternalBasicTrainingSeries extends BaseBasicTrainingSeries {
  active: InternalTrainingSeries['active'];
  deletedAt: InternalTrainingSeries['deletedAt'];
  owner: InternalTrainingSeries['owner'];
}

export type InternalTrainingSeriesList = ResourceList<Partial<InternalBasicTrainingSeries>>;

export interface InternalListTrainingSeriesParams extends Omit<ListParamsStrict, 'all' | 'archived'> {
  active?: boolean;
  id?: number[];
  name?: string;
  ownerEmail?: string;
  scope?: AssetListScope;
}

export interface InternalCreateTrainingSeriesRequestBody
  extends Pick<
    InternalAssetCreateRequestBody,
    'access' | 'active' | 'description' | 'label' | 'language' | 'name' | 'objectives' | 'prerequisites' | 'skillLevel' | 'tags'
  > {
  endTime: InternalTrainingSeries['endTime'];
  location: InternalTrainingSeries['location'];
  parents?: ResourceIdObject[];
  schedule: InternalTrainingSeries['schedule'];
  startTime: InternalTrainingSeries['startTime'];
  timezone: InternalTrainingSeries['timezone'];
  timezoneList: InternalTrainingSeries['timezoneList'];
  timezoneOffset: InternalTrainingSeries['timezoneOffset'];
  virtual: InternalTrainingSeries['virtual'];
}

export interface InternalUpdateTrainingSeriesRequestBody extends Partial<InternalCreateTrainingSeriesRequestBody> {
  coverImage?: string | null;
  deletedAt?: null;
  thumbnail?: string | null;
}
