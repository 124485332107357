/**
 * Fade In Animation Component
 *
 * This component creates a fade in animation
 */

import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

/**
 * Class representing the Fade In animation component
 */
export const FadeInAnimation: AnimationTriggerMetadata = trigger('fadeIn', [
  transition(':enter', [style({ opacity: 0 }), animate(250, style({ opacity: 1 }))]),
]);
