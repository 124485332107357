import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

/**
 * Class representing the Redirect Guard service
 */
@Injectable()
export class RedirectGuard {
  /**
   * Redirect the user
   * @param route - Activate route snapshot
   * @param state - Router state snapshot
   * @returns If route is allowed
   */
  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    window.location.href = route.data['externalUrl'];
    return true;
  }
}
