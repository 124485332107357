import { ListParamsStrict, ListResourceScope, ParamValueCondition } from './list-params';
import { Asset, ResourceList } from './shared';
import { Values } from './type-utils';
import { Registration } from './registration';

export class UpdateAetherEnvironmentClass {
  startTime?: string | Date;
  endTime?: string | Date;
  timezone?: string;
  eventId?: Asset['id'];
  initialized?: boolean;
  templateId?: string;
  name?: string;
  costCenter?: number;
  aetherId?: string;
  aetherProvisioningStatus?: AetherProvisioningStatus;
  aetherHealthStatus?: AetherHealthStatus;
  aetherResources?: any;
  deletedAt?: string | Date | null;
}

export class CreateAetherEnvironmentClass {
  startTime: string | Date;
  endTime: string | Date;
  timezone: string;
  eventId: Asset['id'];
  templateId: string;
  name: string;
  costCenter?: number;
}

export class AetherEnvironmentClass extends CreateAetherEnvironmentClass {
  readonly id: number;
  readonly createdAt: string | Date;
  updatedAt: string | Date;

  initialized: boolean;

  aetherId?: string;
  aetherProvisioningStatus?: AetherProvisioningStatus;
  aetherHealthStatus?: AetherHealthStatus;
  aetherResources?: any; // TODO - further define structure of Resources
  deletedAt?: string | Date | null;

  event?: Asset;
  registrations?: Registration[];
}

export interface UpdateAetherEnvironment extends UpdateAetherEnvironmentClass {}
export interface CreateAetherEnvironment extends CreateAetherEnvironmentClass {}
export interface AetherEnvironment extends AetherEnvironmentClass {}

export const AetherProvisioningStatus = {
  Deprovisioning: 'DEPROVISIONING',
  Failed: 'FAILED',
  Provisioning: 'PROVISIONING',
  Running: 'RUNNING',
  Scheduled: 'SCHEDULED',
  Stopped: 'STOPPED',
} as const;
export type AetherProvisioningStatus = Values<typeof AetherProvisioningStatus>;

export const AetherHealthStatus = {
  Healthy: 'HEALTHY',
  Pending: 'PENDING',
  Unhealthy: 'UNHEALTHY',
} as const;
export type AetherHealthStatus = Values<typeof AetherHealthStatus>;

export type AetherEnvironmentList = ResourceList<AetherEnvironment>;

export interface InternalAetherEnvironmentListParams extends ListParamsStrict {
  id?: number[];
  startTime?: ParamValueCondition;
  endTime?: ParamValueCondition;
  timezone?: string;
  eventId?: Asset['id'];
  name?: string;
  initialized?: boolean;
  aetherId?: string;
  provisioningStatus?: AetherProvisioningStatus;
  healthStatus?: AetherHealthStatus;
  scope?: ListResourceScope;
  registrations?: boolean;
}

export const AetherEnvironmentUseCase = {
  Individual: 'individual',
  Group: 'group',
} as const;
export type AetherEnvironmentUseCase = Values<typeof AetherEnvironmentUseCase>;

export type AetherEventConfiguration = Pick<AetherEnvironment, 'startTime' | 'endTime' | 'timezone' | 'templateId' | 'name'> & {
  environmentUseCase: AetherEnvironmentUseCase;
  costCenter?: number;
};

export interface AetherSecretConfig {
  apiUrl: string;
  jwtToken: string;
}
