import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AdminSharedService } from '../../admin/shared/admin-shared.service';
import { AdminComponent } from '../../shared/types';

/**
 * Class representing the Admin Resolve service
 */
@Injectable()
export class AdminResolver {
  /**
   * Admin resolver service
   * @param adminService - Admin shared service
   */
  constructor(private readonly adminService: AdminSharedService) {}

  /**
   * Run middleware when a route resolves
   * @param route - Activate route information
   * @param state - Router state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.updateRecentlyUsedComponents(state.url);
  }

  /**
   * Updates the user's Recently Used list
   * @param url - The target URL
   */
  updateRecentlyUsedComponents(url: string): void {
    const adminComponents: AdminComponent[] = [];
    this.adminService.adminComponentGroups.forEach((componentGroup) => adminComponents.push(...componentGroup.components));

    const path = url.split('?')[0];
    const usedComponent = adminComponents.find((adminComponent) => path === adminComponent.path);

    if (usedComponent) {
      let recentlyUsedComponents = this.adminService.preferences.dashboard.recentlyUsedComponents.slice();

      recentlyUsedComponents = recentlyUsedComponents.filter((recentlyUsedComponent) => recentlyUsedComponent.name !== usedComponent.name);

      recentlyUsedComponents.unshift(usedComponent);
      recentlyUsedComponents = recentlyUsedComponents.slice(0, 4);

      this.adminService.preferences = {
        ...this.adminService.preferences,
        dashboard: { recentlyUsedComponents },
      };
    }
  }
}
