import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FlagNames } from '@dtu/types';
import { CloudbeesProvider } from 'cloudbees-openfeature-provider-browser';
import { environment } from '../environments/environment';
import { AppService } from './app.service';

/**
 * Class representing the App Init Service
 *
 * This class handles initializes that needs to be ready before the app is rendered
 */
@Injectable()
export class AppInitService {
  /**
   * App Init Service
   * @param appService - Global application service
   * @param router - Angular router
   */
  constructor(private readonly appService: AppService, private readonly router: Router) {}

  /**
   * Initialize required entities before application is rendered
   */
  async initApp(): Promise<void> {
    await this.initFeatureFlags();
    await this.initMaintenanceScreen();

    return new Promise<void>((resolve, _reject) => {
      resolve();
    });
  }

  /**
   * Initialize Feature Flagging for application
   */
  private async initFeatureFlags(): Promise<void> {
    const provider = await CloudbeesProvider.build(environment.cloudbeesKey);
    this.appService.initFeatureManagement(provider);
  }

  /**
   * Initialize maintenance screen for application
   */
  private async initMaintenanceScreen(): Promise<void> {
    if (await this.appService.flagClient.getBooleanValue(FlagNames.EnableMaintenanceScreen, false)) {
      this.router.navigate(['/', 'maintenance']);
    }
  }
}
