import { ListParamsStrict } from './list-params';
import {
  Access,
  AssetAnnouncement,
  AssetType,
  BasicAsset,
  BasicTag,
  BasicUser,
  InternalAssetCreateRequestBody,
  ResourceIdObject,
  ResourceList,
} from './shared';
import { BasicSurvey } from './surveys';
import { Values } from './type-utils';

export interface BaseCertificationSeries {
  access: Access;
  announcements: AssetAnnouncement[];
  coverImage: string | null;
  createdAt: string;
  description: string | null;
  exams: BasicAsset[];
  id: number;
  location: string;
  name: string;
  owner: BasicUser | null;
  objectives: string | null;
  prerequisites: string | null;
  surveys: BasicSurvey[];
  tags: BasicTag[];
  timezone: string;
  timezoneOffset: string;
  thumbnail: string;
  type: AssetType.CertificationSeries;
  updatedAt: string;
  virtual: boolean;
}

export interface CertificationSeries extends BaseCertificationSeries {
  timeSlots: TimeSlot[];
}

export interface InternalCertificationSeries extends BaseCertificationSeries {
  active: boolean;
  label: string | null;
  deletedAt: string | null;
  timeSlots: InternalTimeSlot[];
}

export interface InternalBasicCertificationSeries
  extends Pick<InternalCertificationSeries, 'id' | 'name' | 'active' | 'owner' | 'createdAt' | 'updatedAt' | 'deletedAt'> {}

export interface TimeSlot {
  endTime: string;
  openSeats: number;
  registered: boolean;
  seatLimit: number;
  startTime: string;
}

export type InternalTimeSlot = Omit<TimeSlot, 'registered'>;

export type InternalCertificationSeriesList = ResourceList<InternalBasicCertificationSeries>;
export interface InternalCertificationSeriesListParams extends Omit<ListParamsStrict, 'all' | 'archive'> {
  active?: boolean;
  id?: number;
  name?: string;
  ownerEmail?: string;
  scope?: InternalCertificationSeriesListScope;
}
export const InternalCertificationSeriesListScope = {
  All: 'all',
  Archived: 'archived',
  Owned: 'owned',
} as const;
export type InternalCertificationSeriesListScope = Values<typeof InternalCertificationSeriesListScope>;

export interface InternalCreateCertificationSeriesRequestBody
  extends Pick<
    InternalAssetCreateRequestBody,
    'access' | 'active' | 'description' | 'label' | 'name' | 'objectives' | 'prerequisites' | 'surveys' | 'tags'
  > {
  exams: ResourceIdObject[];
  location: string;
  timeSlots: Pick<TimeSlot, 'endTime' | 'seatLimit' | 'startTime'>[];
  timezone: string;
  timezoneOffset: string;
  type: AssetType.CertificationSeries;
  virtual: boolean;
}

export interface InternalUpdateCertificationSeriesRequestBody extends Partial<InternalCreateCertificationSeriesRequestBody> {
  coverImage?: string | null;
  deletedAt?: null;
  thumbnail?: string | null;
}

export interface RegisterForCertificationSeriesExamRequestBody {
  examId: number;
  timeSlot: Pick<TimeSlot, 'startTime' | 'endTime'>;
}
