<div
  class="dtu-global-banner"
  [class.alert]="config.profile === GLOBAL_BANNER_PROFILE.Alert"
  [class.warning]="config.profile === GLOBAL_BANNER_PROFILE.Warning"
  *ngIf="config && !isDismissed"
>
  <div class="dtu-hidden-flex"></div>
  <div class="dtu-banner-text">
    <div class="dtu-secondary-banner-text" [innerHtml]="config.secondaryText" *ngIf="config.secondaryText"></div>
    <div class="dtu-primary-banner-text" [innerHtml]="config.primaryText" *ngIf="config.primaryText"></div>
  </div>
  <div class="dtu-banner-actions">
    <button aria-label="Dismiss banner" (click)="isDismissed = true" mat-icon-button matTooltip="Dismiss">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
