import { RequiredKeys } from '@prisma/client/runtime/library';
import { ListOrder, ListParams } from './list-params';
import { ResourceList, Tag } from './shared';

class UpdateTagFilterClass {
  sort: number;
}

class CreateTagFilterClass extends UpdateTagFilterClass {
  tagId: number;
  tagFilterSectionId: number;
}

class TagFilterClass extends CreateTagFilterClass {
  readonly createdAt: Date;
  updatedAt: Date;
  tag?: Tag;
}

export class TagFilter implements TagFilter {}

export interface UpdateTagFilter extends UpdateTagFilterClass {}
export interface CreateTagFilter extends CreateTagFilterClass {}
export interface TagFilter extends TagFilterClass {}

// TODO - Update this constant as it currently resolves to an empty array
export const TAG_FILTER_REQUIRED_PROPERTIES = Object.keys(new TagFilterClass()) as Array<RequiredKeys<TagFilter>>;

export interface TagFilterListParams extends Omit<ListParams, 'all' | 'attributes' | 'archive' | 'sort'> {
  order?: ListOrder;
  tag?: boolean;
}

export interface TagFilterGetParams {
  tag?: boolean;
}

export type TagFilterList = ResourceList<TagFilter>;
