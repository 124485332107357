export interface ExamApprovalVerificationRequestBody {
  password: string;
}

export interface VerifiedExamRegistration {
  proctorId: number;
  examName: string;
  startTime: string;
  approved: boolean;
}
