import { ListParams, ListParamsStrict, ListSharedResourceScope } from './list-params';
import { BasicAsset, BasicUser } from './shared';

export interface Report {
  readonly id?: number;
  type?: string;
  name?: string;
  description?: string;
  settings?: {
    audience?: string[];
    complete?: string;
    region?: string;
    status?: string;
    userType?: string[];
    userRegion?: string;
  };
  assets?: BasicAsset[];
  ownerId?: number;
  owner?: BasicUser;
  viewers?: BasicUser[];
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

export const ReportListScope = ListSharedResourceScope;
export type ReportListScope = ListSharedResourceScope;

export interface ReportListParams extends ListParams {
  id?: number;
  type?: string;
  name?: string;
  ownerId?: number;
  ownerEmail?: string;
}

export interface ReportListParamsStrict extends ListParamsStrict {
  id?: number;
  type?: string;
  name?: string;
  ownerId?: number;
  ownerEmail?: string;
  scope?: ReportListScope;
}

export interface ReportList {
  readonly count: number;
  readonly rows: Report[];
}

export interface ShareReportRequestBody {
  userId?: number;
}

export interface UnshareReportRequestBody {
  userId?: number;
}
