<nav class="dtu-global-nav" (window:resize)="manageResize()">
  <div class="dtu-global-nav-primary">
    <div class="dtu-global-brand">University</div>
  </div>
  <div class="dtu-global-nav-secondary">
    <div class="dtu-menu-list dtu-menu-hidden-sm">
      <ng-container *ngFor="let item of menuItems">
        <a class="dtu-menu-item" mat-button [routerLink]="item.path" routerLinkActive="dtu-menu-item-active">
          <span class="dtu-menu-item-title">{{ item.name }}</span>
        </a>
      </ng-container>
    </div>

    <div class="hidden-md">
      <button class="dtu-mobile-nav-menu" mat-button [matMenuTriggerFor]="navMenu" *ngIf="appService.account">
        Menu <mat-icon class="dtu-suffix">expand_more</mat-icon>
      </button>
      <mat-menu #navMenu="matMenu" class="dtu-menu" xPosition="before">
        <a mat-menu-item [routerLink]="item.path" *ngFor="let item of menuItems">{{ item.name }}</a>
      </mat-menu>
    </div>

    <ng-container *ngIf="router.url.indexOf('/account') === -1 && router.url.indexOf('/admin') === -1 && appService.account?.viewingAs">
      <button [matMenuTriggerFor]="viewingAsMenu" mat-button>
        Viewing as {{ appService.account.viewingAsUser.firstName }} {{ appService.account.viewingAsUser.lastName }}
      </button>
      <mat-menu #viewingAsMenu="matMenu" class="dtu-menu" xPosition="before">
        <button (click)="resetUserAccess()" mat-menu-item>Stop viewing as</button>
      </mat-menu>
    </ng-container>

    <div class="dtu-menu-list">
      <button mat-icon-button [matMenuTriggerFor]="helpMenu" matTooltip="Need help?" matTooltipPosition="below">
        <mat-icon>help</mat-icon>
      </button>
      <mat-menu class="dtu-menu" #helpMenu="matMenu" xPosition="before">
        <a mat-menu-item target="_blank" rel="noopener noreferrer" href="https://www.dynatrace.com/dynatrace-university/">
          About the University
        </a>
        <button (click)="navigateToFAQ()" mat-menu-item>FAQs</button>
        <a [href]="supportUrl" mat-menu-item target="_blank" rel="noopener noreferrer">University support</a>
      </mat-menu>

      <dtu-notification-menu label="icon"></dtu-notification-menu>
    </div>

    <button class="dtu-global-account" mat-button [matMenuTriggerFor]="accountMenu" *ngIf="appService.account">
      <div class="dtu-user">
        <div class="dtu-user-profile">
          <img alt="Account profile image" [src]="appService.account.profileImage" *ngIf="appService.account.profileImage" />
          <div class="dtu-user-initials" *ngIf="!appService.account.profileImage">
            {{ appService.account.firstName ? appService.account.firstName[0] : ''
            }}{{ appService.account.lastName ? appService.account.lastName[0] : '' }}
          </div>
        </div>
      </div>
    </button>
    <mat-menu #accountMenu="matMenu" class="dtu-menu" xPosition="before">
      <a routerLink="/admin" mat-menu-item *ngIf="hasAdminRole">Admin console</a>
      <button (click)="appService.setDarkMode(false)" mat-menu-item *ngIf="appService.darkMode">Disable dark mode</button>
      <button (click)="appService.setDarkMode(true)" mat-menu-item *ngIf="!appService.darkMode">Enable dark mode</button>

      <button (click)="signOut()" mat-menu-item>Sign out</button>
    </mat-menu>
  </div>
</nav>
