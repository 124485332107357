import { AssetInstructorType } from '.';
import { ListParamsStrict } from './list-params';
import {
  Access,
  Asset,
  AssetSkillLevel,
  AssetType,
  BasicAssetActivity,
  BasicTag,
  BasicUser,
  InternalAssetCreateRequestBody,
  ModuleType,
  ResourceIdObject,
  ResourceList,
  StrictAssetAnnouncement,
} from './shared';
import { DeepOmit, Values } from './type-utils';
import { BasicSurvey } from './surveys';

export interface BaseCourse {
  access: Access; // For internal will include all teams, for public only teams user is member of
  announcements: StrictAssetAnnouncement[];
  coverImage: string | null;
  createdAt: string;
  description: string | null;
  duration: number;
  id: number;
  instructors: CourseInstructor[];
  language: string;
  moduleCount: number;
  name: string;
  objectives: string | null;
  owner: BasicUser | null; // Should this be internal only? Could possibly expose Dynatrace emails to customers when no necessary.
  prerequisites: string | null;
  skillLevel: AssetSkillLevel;
  surveys: BasicSurvey[];
  tags: BasicTag[];
  thumbnail: string | null;
  type: AssetType.Course;
  updatedAt: string;
}

export interface InternalCourse extends BaseCourse {
  active: boolean;
  completionPercent: number;
  deletedAt: string | null;
  label: string | null;
  sections: InternalCourseSection[];
}

export interface PublicCourse extends BaseCourse {
  activity: BasicAssetActivity;
  favorite: boolean;
  new: boolean;
  sections: CourseSection[];
}

export interface CourseInstructor {
  type: AssetInstructorType;
  user: BasicUser;
}

export interface CourseSection {
  activity: BasicAssetActivity;
  description: string | null; // Is this needed?
  duration: number;
  id: number;
  moduleCount: number;
  modules: CourseModule[];
  name: string;
  required: boolean;
  type: AssetType.Section;
}

export interface InternalCourseSection extends DeepOmit<CourseSection, 'activity'> {
  active: boolean;
  parentCount: number;
}

export type InternalCourseModule = InternalCourse['sections'][number]['modules'][number];

export interface CourseModule {
  activity: BasicAssetActivity;
  description: string | null;
  duration: number;
  id: number;
  name: string;
  required: boolean;
  src: string | null;
  thumbnail: string | null;
  type: ModuleType;
}

export interface BasicCourse {
  access: Access;
  createdAt: string;
  description: string | null;
  favorite: boolean;
  id: number;
  name: string;
  new: boolean;
  skillLevel: AssetSkillLevel;
  thumbnail: string | null;
  type: AssetType.Course;
  updatedAt: string;
}

export type CourseList = ResourceList<Partial<BasicCourse>>;

export const CourseListScope = {
  All: 'all',
  Favorited: 'favorited',
  Owned: 'owned',
} as const;
export type CourseListScope = Values<typeof CourseListScope>;

export interface CourseListParamsStrict extends Omit<ListParamsStrict, 'all' | 'archive'> {
  name?: string;
  public?: boolean;
  scope?: CourseListScope;
}

export interface Course extends Asset {
  assetAccess?: Access;
  selectedModuleCount?: number;
}

export interface InternalCreateCourseRequestBody
  extends Pick<
    InternalAssetCreateRequestBody,
    | 'access'
    | 'active'
    | 'completionPercent'
    | 'description'
    | 'instructors'
    | 'label'
    | 'language'
    | 'name'
    | 'objectives'
    | 'prerequisites'
    | 'skillLevel'
    | 'surveys'
    | 'tags'
  > {
  sections: NewInternalCourseSection[];
}

export interface InternalUpdateCourseRequestBody extends Partial<Omit<InternalCreateCourseRequestBody, 'sections'>> {
  coverImage?: string | null;
  sections?: (NewInternalCourseSection | UpdatedInternalCourseSection)[];
  thumbnail?: string | null;
}

export interface NewInternalCourseSection {
  active: boolean;
  description?: string | null;
  name: string;
  required: boolean;
  modules: ResourceIdObject[];
}

export interface UpdatedInternalCourseSection extends NewInternalCourseSection {
  id: number;
}

export function isNewInternalCourseSection(
  section: UpdatedInternalCourseSection | NewInternalCourseSection,
): section is NewInternalCourseSection {
  return !Boolean(section['id']);
}

export function isUpdatedInternalCourseSection(
  section: UpdatedInternalCourseSection | NewInternalCourseSection,
): section is UpdatedInternalCourseSection {
  return Boolean(section['id']);
}
