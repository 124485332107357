export interface DocumentContent {
  extraVars?: string[];
  [key: string]: any;
}

export interface DocumentMetadata {
  id: string;
  name: string;
  type: string;
  version: string;
  owner: string;
  modificationInfo: {
    createdBy: string;
    createdTime: Date;
    lastModifiedBy: string;
    lastModifiedTime: Date;
  };
  access: ('read' | 'write' | 'delete')[];
}

export interface NotebookConfig {
  metadata: DocumentMetadata;
  content: DocumentContent;
}
