import { AssetFilter } from './asset-filter';
import { AssetType, BasicAssetActivity, BasicTag, ModuleType, StrictBasicUser } from './shared';
import { BasicSurvey } from './surveys';

export interface Series {
  activity: BasicAssetActivity;
  announcements: SeriesAnnouncement[];
  coverImage: string | null;
  createdAt: string;
  description: string | null;
  duration: number;
  enrollment: SeriesEnrollment | null;
  favorite: boolean;
  filters: AssetFilter<Omit<Series, 'filters'>>[];
  id: number;
  language: string;
  moduleCount: number;
  name: string;
  objectives: string | null;
  prerequisites: string | null;
  sections: SeriesSection[];
  skillLevel: string;
  surveys: Required<BasicSurvey>[];
  tags: Required<BasicTag>[];
  type: AssetType.Series;
  updatedAt: string;
}

export interface SeriesSection {
  id: number;
  name: string;
  description: string;
  modules: SeriesModule[];
}
export interface SeriesModule {
  activity: BasicAssetActivity;
  createdAt: string;
  description: string;
  duration: number;
  id: number;
  instructors: StrictBasicUser[];
  name: string;
  new: boolean;
  src: string;
  tags: Required<BasicTag>[];
  thumbnail: string;
  type: ModuleType;
  updatedAt: string;
}

export interface SeriesAnnouncement {
  id: number;
  title: string;
  description: string;
  user: StrictBasicUser | null;
  createdAt: string;
}

export interface SeriesEnrollment {
  provideEmailNotifications: boolean;
  provideWebNotifications: boolean;
}
