/**
 * Authentication Service
 *
 * This service contains general operations for the DTU Authentication resource
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DtuRole, LogoutResponse, User, UserPermissions } from '@dtu/types';
import { environment } from '../../../environments/environment';

/**
 * Class representing the Authentication API Service
 */
@Injectable()
export class AuthApiService {
  /**
   * Auth API Service
   * @param http - HTTP Client
   */
  constructor(private readonly http: HttpClient) {}

  /**
   * Returns the URL that a user can be directed to in order to authenticate.
   */
  get signInUrl(): string {
    return environment.loginUrl;
  }

  /**
   * Returns the URL for logging out the current user. A logout can be
   * initiated by sending a POST request to the returned URL.
   */
  get signOutUrl(): string {
    return environment.logoutUrl;
  }

  /**
   * Get user session
   * @returns The logged in DTU user
   */
  getSession(): Promise<User> {
    return this.http.get<User>(`${environment.api}/auth/session`, { withCredentials: true }).toPromise();
  }

  /**
   * Get account permissions account
   * @param user - User details
   */
  getAccountPermissions(user: User): UserPermissions {
    const permissions: UserPermissions = {};
    const hasRole = (role: DtuRole) => user.roles.some((assignedRole) => assignedRole.name === role);

    permissions.accessAdmin = user.admin || hasRole(DtuRole.AccessAdmin);
    permissions.accessViewer = permissions.accessAdmin || hasRole(DtuRole.AccessViewer);

    permissions.contentAdmin = user.admin || hasRole(DtuRole.ContentAdmin);
    permissions.contentEditor = permissions.contentAdmin || hasRole(DtuRole.ContentEditor);
    permissions.contentViewer = permissions.contentEditor || hasRole(DtuRole.ContentViewer);

    permissions.eventAdmin = user.admin || hasRole(DtuRole.EventAdmin);
    permissions.eventEditor = permissions.eventAdmin || hasRole(DtuRole.EventEditor);
    permissions.eventViewer = permissions.eventEditor || hasRole(DtuRole.EventViewer);

    permissions.labAdmin = user.admin || hasRole(DtuRole.LabAdmin);
    permissions.labEditor = permissions.labAdmin || hasRole(DtuRole.LabEditor);
    permissions.labViewer = permissions.labEditor || hasRole(DtuRole.LabViewer);

    permissions.financeAdmin = user.admin || hasRole(DtuRole.FinanceAdmin);
    permissions.financeViewer = permissions.financeAdmin || hasRole(DtuRole.FinanceViewer);

    permissions.reportAdmin = user.admin || hasRole(DtuRole.ReportAdmin);
    permissions.reportEditor = permissions.reportAdmin || hasRole(DtuRole.ReportEditor);
    permissions.reportViewer = permissions.reportEditor || hasRole(DtuRole.ReportViewer);

    permissions.surveyAdmin = user.admin || hasRole(DtuRole.SurveyAdmin);
    permissions.surveyEditor = permissions.surveyAdmin || hasRole(DtuRole.SurveyEditor);
    permissions.surveyViewer = permissions.surveyEditor || hasRole(DtuRole.SurveyViewer);

    return permissions;
  }

  /**
   * Check if the role name is a DTU Role
   * @param role - Role name to check
   * @returns If the role is a DTU role
   */
  isDtuRole(role: any): role is DtuRole {
    return Object.values(DtuRole).indexOf(role) !== -1;
  }

  /**
   * Navigate the user to the login page
   * @param redirectUrl - URL to redirect to after login
   */
  signIn(redirectUrl?: string): void {
    const relayQuery = redirectUrl ? `?RelayState=${encodeURIComponent(redirectUrl)}` : '';
    window.location.href = `${this.signInUrl}${relayQuery}`;
  }

  /**
   * Handle user log out
   */
  async signOut(): Promise<LogoutResponse> {
    return this.http.post<LogoutResponse>(this.signOutUrl, null, { withCredentials: true }).toPromise();
  }
}
