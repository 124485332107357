/**
 * Employee Guard Service
 *
 * This service checks to see if the user is an employee before completing route
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppService } from '../../app.service';
import { AuthApiService } from '../api/auth.service';

/**
 * Class representing the Employee Guard service
 */
@Injectable()
export class EmployeeGuard {
  /**
   * Employee Guard service
   * @param appService - Global application service
   * @param authApiService - Auth API service
   * @param router - Angular router
   */
  constructor(private readonly appService: AppService, private readonly authApiService: AuthApiService, private readonly router: Router) {}

  /**
   * Check if user is an employee before routing
   * @param route - Activate route snapshot
   * @param state - Router state snapshot
   * @returns If route is allowed
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      const user = await this.authApiService.getSession();
      this.appService.setAccount(user);

      if (this.appService.account.type === 'employee') {
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }
    } catch (error) {
      const redirectUrl = `${window.origin}${state.url}`;
      this.authApiService.signIn(redirectUrl);
      return false;
    }
  }

  /**
   * For child routes, check if user is logged in before routing
   * @param route - Activate route snapshot
   * @param state - Router state snapshot
   * @returns If route is allowed
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
