import { ResourceIdObject } from './shared';

export enum OrderStatus {
  Complete = 'complete',
  Pending = 'pending',
  Cancelled = 'cancelled',
}

export enum OrderType {
  Card = 'card',
  Contract = 'contract',
  Employee = 'employee',
  Other = 'other',
  Points = 'points',
}

export interface CreateOrderRequestBody {
  type: OrderType;
  discountCode: string;
  events: ResourceIdObject[];
  cardToken: string;
}

export enum OrderListEventSort {
  StartTime = 'startTime',
  EndTime = 'endTime',
  Month = 'eventMonth',
  FiscalYear = 'eventFiscalYear',
}
