<div class="dtu-new-styles" *ngIf="!isFullscreen">
  <div class="dtu-app-container" *ngIf="appService.account && !isOverviewOutletActivated">
    <div class="dtu-main-app">
      <dt-common-navigation
        [variant]="appService.darkMode ? 'dark' : 'transparent-light-bg-light'"
        size="small"
        id="dt-nav-main"
      ></dt-common-navigation>

      <dtu-global-nav></dtu-global-nav>
      <dtu-global-banner></dtu-global-banner>

      <main class="dtu-content">
        <router-outlet></router-outlet>
      </main>
      <div class="dtu-footer-spacer"></div>
    </div>
    <dt-common-footer size="small" variant="gradient" id="dt-footer-main"></dt-common-footer>
  </div>

  <div [hidden]="!isOverviewOutletActivated">
    <dt-common-navigation variant="light" *ngIf="isOverviewOutletActivated && !isCommonNavHidden"> </dt-common-navigation>

    <router-outlet
      name="overview"
      #overviewOutlet="outlet"
      (activate)="isOverviewOutletActivated = true"
      (deactivate)="isOverviewOutletActivated = false"
    ></router-outlet>
  </div>
</div>

<router-outlet *ngIf="isFullscreen"></router-outlet>
