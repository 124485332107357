/**
 * Notifications Service
 *
 * This service contains the CRUD operations for the DTU Notification resource
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification, NotificationList, NotificationListParams } from '@dtu/types';
import { environment } from '../../../environments/environment';

/**
 * Class representing the Notification API Service
 */
@Injectable()
export class NotificationApiService {
  /**
   * Notification API Service
   * @param http - HTTP Client
   */
  constructor(private readonly http: HttpClient) {}

  /**
   * List notifications
   * @param params - Notification attributes and pagination options to filter list on
   * @returns A list of notifications
   */
  listNotifications(params: NotificationListParams): Promise<NotificationList> {
    let urlParams = new HttpParams();
    if (params.attributes) {
      urlParams = urlParams.set('attributes', params.attributes);
    }
    if (params.archive) {
      urlParams = urlParams.set('archive', params.archive.toString());
    }
    if (params.page) {
      urlParams = urlParams.set('page', params.page.toString());
    }
    if (params.limit) {
      urlParams = urlParams.set('limit', params.limit.toString());
    }
    if (params.order) {
      urlParams = urlParams.set('order', params.order);
    }
    if (params.sort) {
      urlParams = urlParams.set('sort', params.sort);
    }
    if (params.id) {
      urlParams = urlParams.set('id', params.id.toString());
    }
    if (params.type) {
      urlParams = urlParams.set('type', params.type);
    }
    if (params.delivery) {
      urlParams = urlParams.set('delivery', params.delivery);
    }
    if (params.deliveryTime) {
      urlParams = urlParams.set('deliveryTime', params.deliveryTime);
    }
    if (params.userEmail) {
      urlParams = urlParams.set('userEmail', params.userEmail);
    }

    return this.http.get<NotificationList>(`${environment.api}/notifications`, { params: urlParams, withCredentials: true }).toPromise();
  }

  /**
   * Create a new notification
   * @param notificationAttributes - Notification attributes to create
   * @returns The created notification
   */
  createNotification(notificationAttributes: Notification): Promise<Notification> {
    return this.http.post<Notification>(`${environment.api}/notifications`, notificationAttributes, { withCredentials: true }).toPromise();
  }

  /**
   * Get an existing notification
   * @param notificationId - Numeric ID of the notification to get
   * @returns The requested notification
   */
  getNotification(notificationId: number): Promise<Notification> {
    return this.http.get<Notification>(`${environment.api}/notifications/${notificationId}`, { withCredentials: true }).toPromise();
  }

  /**
   * Update an existing notification
   * @param notificationId - Numeric ID of the notification to update
   * @param notificationAttributes - Notification attributes to update
   * @returns The updated notification
   */
  updateNotification(notificationId: number, notificationAttributes: Notification): Promise<Notification> {
    return this.http
      .put<Notification>(`${environment.api}/notifications/${notificationId}`, notificationAttributes, { withCredentials: true })
      .toPromise();
  }

  /**
   * Delete an existing notification
   * @param notificationId - Numeric ID of the notification to delete
   * @returns The deleted notification
   */
  deleteNotification(notificationId: number): Promise<Notification> {
    return this.http.delete<Notification>(`${environment.api}/notifications/${notificationId}`, { withCredentials: true }).toPromise();
  }
}
