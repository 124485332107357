import { ListParamsStrict } from './list-params';
import {
  Access,
  AssetListScope,
  AssetSkillLevel,
  AssetType,
  BasicAssetActivity,
  BasicTag,
  BasicUser,
  InternalAssetCreateRequestBody,
  ResourceList,
  SupportedLanguage,
} from './shared';
import { BasicSurvey } from './surveys';

export interface BaseLabGuide {
  access: Access;
  coverImage: string | null;
  createdAt: string;
  description: string | null;
  duration: number;
  id: number;
  language: string | SupportedLanguage;
  name: string;
  objectives: string | null;
  owner: BasicUser | null;
  prerequisites: string | null;
  skillLevel: AssetSkillLevel;
  surveys: BasicSurvey[];
  tags: BasicTag[];
  thumbnail: string | null;
  type: AssetType.LabGuide;
  updatedAt: string;
}

export interface LabGuide extends BaseLabGuide {
  activity: BasicAssetActivity;
  favorite: boolean;
  new: boolean;
  sections: LabGuideSection[];
}

export type InternalLabGuideAttribute = keyof InternalLabGuide;

export interface InternalLabGuide extends BaseLabGuide {
  active: boolean;
  completionPercent: number;
  deletedAt: string | null;
  label: string | null;
  sections: InternalLabGuideSection[];
}
export interface BaseLabGuideSection {
  duration: number;
  id: number;
  name: string;
  type: AssetType.Section;
}
export interface LabGuideSection extends BaseLabGuideSection {
  activity: BasicAssetActivity;
  exercises: LabGuideExercise[];
}

export interface InternalLabGuideSection extends BaseLabGuideSection {
  active: boolean;
  exercises: InternalLabGuideExercise[];
}

export interface BaseLabGuideExercise {
  html: string;
  markdown: string;
  duration: number;
  id: number;
  name: string;
  type: AssetType.LabGuideExercise;
}

export interface LabGuideExercise extends BaseLabGuideExercise {
  activity: BasicAssetActivity;
}

export interface InternalLabGuideExercise extends BaseLabGuideExercise {
  active: boolean;
  markdown: string;
}

export interface InternalLabGuideListEntry
  extends Pick<InternalLabGuide, 'access' | 'active' | 'createdAt' | 'deletedAt' | 'id' | 'name' | 'owner' | 'updatedAt'> {}

export type InternalLabGuideList = ResourceList<InternalLabGuideListEntry>;

export interface InternalListLabGuideParams extends Omit<ListParamsStrict, 'all' | 'archived'> {
  active?: boolean;
  id?: number[];
  name?: string;
  ownerEmail?: string;
  scope?: AssetListScope;
}

export interface InternalCreateLabGuideRequestBody
  extends Pick<
    InternalAssetCreateRequestBody,
    | 'access'
    | 'active'
    | 'completionPercent'
    | 'description'
    | 'duration'
    | 'label'
    | 'language'
    | 'name'
    | 'objectives'
    | 'prerequisites'
    | 'skillLevel'
    | 'surveys'
    | 'tags'
  > {
  sections: NewInternalLabGuideSection[];
}

export interface InternalUpdateLabGuideRequestBody extends Partial<Omit<InternalCreateLabGuideRequestBody, 'sections'>> {
  coverImage?: string | null;
  deletedAt?: null;
  sections?: (NewInternalLabGuideSection | UpdatedInternalLabGuideSection)[];
  thumbnail?: string | null;
}

export interface NewInternalLabGuideSection extends Pick<InternalAssetCreateRequestBody, 'active' | 'name' | 'duration'> {
  exercises: NewInternalLabGuideExercise[];
}

export interface UpdatedInternalLabGuideSection extends Omit<NewInternalLabGuideSection, 'exercises'> {
  exercises: (NewInternalLabGuideExercise | UpdatedInternalLabGuideExercise)[];
  id: number;
}

export interface NewInternalLabGuideExercise extends Pick<InternalAssetCreateRequestBody, 'active' | 'duration' | 'name'> {
  html?: InternalAssetCreateRequestBody['description'];
  markdown?: InternalAssetCreateRequestBody['objectives'];
}

export interface UpdatedInternalLabGuideExercise extends NewInternalLabGuideExercise {
  id: number;
}

export interface LabGuideGeneratorSection {
  id: string;
  name: string;
  html: string;
  markdown: string;
  activityList: LabGuideGeneratorSection[];
}

export interface LabGuideGeneratorGuide {
  source: string;
  sections: LabGuideGeneratorSection[];
}
