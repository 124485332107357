import { Values } from './type-utils';

export interface SupportTicket {
  summary: string;
  description: string;
  components: string[];
}

export interface SupportTicketResponse {
  url: string;
  reporterAdded: boolean;
}

export const SupportTicketType = {
  Authentication: 'authentication',
  Certification: 'certification',
  FeatureRequest: 'feature-request',
  LabEnvironment: 'lab-environment',
  LiveTrainingEvents: 'live-training-events',
  OnDemandLearning: 'on-demand-learning',
  Teams: 'teams',
} as const;
export type SupportTicketType = Values<typeof SupportTicketType>;

export interface CreateSupportTicketRequestBody {
  description: string;
  summary: string;
  type: SupportTicketType;
}

export interface CreateSupportTicketResponse {
  url: string;
}
