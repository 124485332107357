/**
 * Notification Menu Component
 *
 * This component creates the global notification menu
 */

import { Component, Input, OnInit } from '@angular/core';
import { ListOrder, Notification, NotificationList, NotificationListParams } from '@dtu/types';
import { AppService } from '../../../app.service';
import { NotificationApiService } from '../../../shared/api/notifications.service';

/**
 * Class representing the Notification Menu component
 */
@Component({
  selector: 'dtu-notification-menu',
  styleUrls: ['./notification-menu.component.scss'],
  templateUrl: './notification-menu.component.html',
})
export class NotificationMenuComponent implements OnInit {
  // Initialize inputs
  @Input() label: string;

  // Initialize notification list
  notificationList: NotificationList;

  // Initialize parameters
  params: NotificationListParams = {
    attributes: 'id,type,deliveryTime,data',
    page: 1,
    limit: 50,
    order: ListOrder.Descending,
    sort: 'deliveryTime',
    delivery: 'web',
  };

  /**
   * Notification Menu component
   * @param appService - Global application service
   * @param notificationApiService - Notification API service
   */
  constructor(private readonly appService: AppService, private readonly notificationApiService: NotificationApiService) {}

  /**
   * Initialize component
   */
  ngOnInit(): void {
    this.listNotifications();
  }

  /**
   * List notifications
   */
  async listNotifications(): Promise<void> {
    if (this.appService.account) {
      try {
        this.params.deliveryTime = new Date().toISOString();
        this.notificationList = await this.notificationApiService.listNotifications(this.params);
      } catch (error) {
        this.appService.componentError(error, 'Unable to list notifications');
      }
    }
  }

  /**
   * Dismiss notification
   * @param notification - Notification to dismiss
   */
  async dismissNotification(notification: Notification): Promise<void> {
    try {
      await this.notificationApiService.deleteNotification(notification.id);
      this.listNotifications();
    } catch (error) {
      this.appService.componentError(error, 'Unable to dismiss notification');
    }
  }
}
