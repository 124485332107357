/**
 * Strip HTML Pipe
 *
 * This pipe strips HTML from a string
 */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Class representing a Strip HTML pipe
 */
@Pipe({ name: 'stripHTML' })
export class StripHTMLPipe implements PipeTransform {
  /**
   * Transform a string to remove HTML
   * @param html - HTML to strip
   * @returns The stripped HTML
   */
  transform(html: string): string {
    if (html) {
      return html.replace(/<.*?>/g, '');
    } else {
      return '';
    }
  }
}
