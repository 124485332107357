export const environment = {
  type: 'staging',
  production: true,
  api: 'https://api.university-staging.dynatracelabs.com/v1',
  apiV2: 'https://api.university-staging.dynatracelabs.com/v2',
  examityLoginUrl: 'https://test.examity.com/Dynatrace/login.aspx',
  loginUrl: 'https://api.university-staging.dynatracelabs.com/auth/login',
  logoutUrl: 'https://api.university-staging.dynatracelabs.com/auth/logout',
  marketingUrl: 'https://www.dynatrace.com/dynatrace-university/',
  searchUrl: 'https://staging-search.ocs.dynatrace.com',
  cdn: 'https://university-staging.dynatracelabs.com',
  authRedirect: 'https://university-staging.dynatracelabs.com/account/dashboard',
  assetBucket: 'dtu-assets-stg',
  uploadBucket: 'dtu-upload-stg',
  stripeKey: 'pk_test_wVrDPFdJ0QCaYtcUmM3hjWry',
  transcodePipelineId: '1550172441116-y4rcdp',
  transcodePresetId: '1552484774286-b8wabk',
  transcodeQueueUrl: 'https://sqs.us-east-1.amazonaws.com/555030778142/dtu-transcoder-queue-stg',
  supportUrl: 'https://one-staging.dynatracelabs.com/hc/en-us/requests/new?requesttype=University',
  navigationUrl: 'https://www.dynatrace.com/common-components/support-navigation-staging/',
  stripePaymentUrl: 'https://dashboard.stripe.com/test/payments/',
  cloudbeesKey: '640b4d30674cf17746271c17',
  perform2025CertSeriesId: 48033,
  onDemandLibraryCategoryIds: {
    dynatrace: 25291,
    dynatraceManaged: 24050,
    webinarSeries: 24051,
    valueStories: 24161,
  },
};
