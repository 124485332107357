export interface AccountManagementGroup {
  uuid: string;
  name: string;
  owner: string;
  description: string;
  hidden: boolean;
  createdAt: string | Date;
  updatedAt: string | Date;
}

export interface AccountManagementGroupList {
  count: number;
  items: AccountManagementGroup[];
}

export interface AccountManagementPolicy {
  uuid: string;
  name: string;
  description?: string;
  tags?: string[];
  statementQuery?: string;
}
