import { Values } from './type-utils';

export const ProctorUBrowserTabsConfig = {
  Allowed: 'allowed',
  Restricted: 'restricted',
} as const;
export type ProctorUBrowserTabsConfig = Values<typeof ProctorUBrowserTabsConfig>;

export const ProctorUCopyPasteImagesConfig = {
  Disabled: 'disabled',
  Enabled: 'enabled',
} as const;
export type ProctorUCopyPasteImagesConfig = Values<typeof ProctorUCopyPasteImagesConfig>;

export const ProctorUWindowSizeAllowedConfig = {
  FullScreen: 'fullscreen',
  Maximized: 'maximized',
  Resized: 'resized',
} as const;
export type ProctorUWindowSizeAllowedConfig = Values<typeof ProctorUWindowSizeAllowedConfig>;

export const ProctorULostFocusConfig = {
  Allowed: 'allowed',
  Restricted: 'restricted',
} as const;
export type ProctorULostFocusConfig = Values<typeof ProctorULostFocusConfig>;

export interface ProctorUCustomPresetConfig {
  browser_tabs: ProctorUBrowserTabsConfig;
  allow_copy_paste: ProctorUCopyPasteImagesConfig;
  window_size_allowed: ProctorUWindowSizeAllowedConfig;
  lost_focus: ProctorULostFocusConfig;
}

export interface ProctorUCustomPresetConfigNormalized {
  browserTabsConfig: ProctorUCustomPresetConfig['browser_tabs'];
  copyPasteImagesConfig: ProctorUCustomPresetConfig['allow_copy_paste'];
  windowSizeAllowedConfig: ProctorUCustomPresetConfig['window_size_allowed'];
  lostFocusConfig: ProctorUCustomPresetConfig['lost_focus'];
}

export const ProctorUPresetValues = {
  Low: 'low',
  Medium: 'medium',
  High: 'high',
  Custom: 'custom',
} as const;
export type ProctorUPresetValues = Values<typeof ProctorUPresetValues>;

export interface ProctorUWhiteListUrl {
  url: string;
  open_on_start?: boolean;
}

export interface ProctorUWhiteListUrlNormalized {
  url: ProctorUWhiteListUrl['url'];
  openOnStart?: ProctorUWhiteListUrl['open_on_start'];
}

export interface ProctorUBluebirdQuery {
  time_sent: string | Date;
  student_id: number;
  first_name: string;
  last_name: string;
  email: string;
  exam_id: string;
  description: string;
  active_date: string | Date;
  end_date: string | Date;
  duration: number;
  exam_url: string;
  exam_password: string;
  department_id: number;
  notify?: string;
  preset?: ProctorUPresetValues;
  permitted_resources_list?: string;
  preset_custom_configurations?: string;
  whitelist_urls?: string;
}

export interface ProctorUBluebirdNormalizedQuery {
  timeSent: ProctorUBluebirdQuery['time_sent'];
  studentId: ProctorUBluebirdQuery['student_id'];
  firstName: ProctorUBluebirdQuery['first_name'];
  lastName: ProctorUBluebirdQuery['last_name'];
  email: ProctorUBluebirdQuery['email'];
  examId: ProctorUBluebirdQuery['exam_id'];
  description: ProctorUBluebirdQuery['description'];
  activeDate: ProctorUBluebirdQuery['active_date'];
  endDate: ProctorUBluebirdQuery['end_date'];
  duration: ProctorUBluebirdQuery['duration'];
  examUrl: ProctorUBluebirdQuery['exam_url'];
  examPassword: ProctorUBluebirdQuery['exam_password'];
  departmentId: ProctorUBluebirdQuery['department_id'];
  notify?: ProctorUBluebirdQuery['notify'];
  preset?: ProctorUBluebirdQuery['preset'];
  permittedResourcesList?: string[];
  presetCustomConfigurations?: ProctorUCustomPresetConfigNormalized;
  whitelistUrls?: ProctorUWhiteListUrlNormalized[];
}

export function normalizedQueryToBluebirdQuery(query: ProctorUBluebirdNormalizedQuery): URLSearchParams {
  const bluebirdQuery: ProctorUBluebirdQuery = {
    time_sent: query.timeSent,
    student_id: query.studentId,
    first_name: query.firstName,
    email: query.email,
    last_name: query.lastName,
    exam_id: query.examId,
    description: query.description,
    active_date: query.activeDate,
    end_date: query.endDate,
    duration: query.duration,
    exam_url: query.examUrl,
    exam_password: query.examPassword,
    department_id: query.departmentId,
  };
  if (query.notify) {
    bluebirdQuery.notify = query.notify;
  }
  if (query.permittedResourcesList) {
    bluebirdQuery.permitted_resources_list = query.permittedResourcesList.join(', ');
  }
  if (query.preset && query.preset === ProctorUPresetValues.Custom) {
    bluebirdQuery.preset = ProctorUPresetValues.Custom;
    bluebirdQuery.preset_custom_configurations = JSON.stringify({
      browser_tabs: query.presetCustomConfigurations?.browserTabsConfig ?? ProctorUBrowserTabsConfig.Allowed,
      allow_copy_paste: query.presetCustomConfigurations?.copyPasteImagesConfig ?? ProctorUCopyPasteImagesConfig.Disabled,
      window_size_allowed: query.presetCustomConfigurations?.windowSizeAllowedConfig ?? ProctorUWindowSizeAllowedConfig.Resized,
      lost_focus: query.presetCustomConfigurations?.lostFocusConfig ?? ProctorULostFocusConfig.Allowed,
    });
  }
  if (query.whitelistUrls) {
    bluebirdQuery.whitelist_urls = JSON.stringify(
      query.whitelistUrls.map((proctorUWhitelistedUrlObject) => {
        const urlObject = { url: proctorUWhitelistedUrlObject.url } as ProctorUWhiteListUrl;
        if (typeof proctorUWhitelistedUrlObject.openOnStart === 'boolean' && proctorUWhitelistedUrlObject.openOnStart !== undefined) {
          urlObject.open_on_start = proctorUWhitelistedUrlObject.openOnStart;
        }
        return urlObject;
      }),
    );
  }

  return new URLSearchParams(bluebirdQuery as any);
}

export interface ProctorUBluebirdExamResponse {
  time_sent: string;
  response_code: number;
  message: string;
  data: {
    url: string;
  };
  error_code?: number;
}

export interface ProctorUBluebirdExamNormalizedResponse {
  timeSent: ProctorUBluebirdExamResponse['time_sent'];
  responseCode: ProctorUBluebirdExamResponse['response_code'];
  message: ProctorUBluebirdExamResponse['message'];
  data: ProctorUBluebirdExamResponse['data'];
  errorCode?: ProctorUBluebirdExamResponse['error_code'];
}

export function bluebirdResponseToNormalizedResponse(response: ProctorUBluebirdExamResponse): ProctorUBluebirdExamNormalizedResponse {
  const normalizedResponse: ProctorUBluebirdExamNormalizedResponse = {
    timeSent: response.time_sent,
    responseCode: response.response_code,
    message: response.message,
    data: response.data,
  };
  if (response.error_code) {
    normalizedResponse.errorCode = response.error_code;
  }
  return normalizedResponse;
}

export const ProctorUWebhookEventType = {
  FulfillmentScheduled: 'event-fulfillment-scheduled',
  ReservationCancelled: 'event-reservation-cancelled',
} as const;
export type ProctorUWebhookEventType = Values<typeof ProctorUWebhookEventType>;

export interface ProctorUWebhookEvent {
  event: {
    type: ProctorUWebhookEventType;
  };
  reservation: {
    number: number;
    external_id: string;
    starts_at: string;
    ends_at: string;
  };
  test_taker: {
    student_id: string;
  };
}

export interface ProctorUNormalizedWebhookEvent {
  event: ProctorUWebhookEvent['event'];
  reservation: {
    number: ProctorUWebhookEvent['reservation']['number'];
    externalId: ProctorUWebhookEvent['reservation']['external_id'];
    startsAt: ProctorUWebhookEvent['reservation']['starts_at'];
    endsAt: ProctorUWebhookEvent['reservation']['ends_at'];
  };
  testTaker: {
    studentId: ProctorUWebhookEvent['test_taker']['student_id'];
  };
}

export function proctoruWebhookEventToNormalizedWebhookEvent(webhookEvent: ProctorUWebhookEvent): ProctorUNormalizedWebhookEvent {
  return {
    event: webhookEvent.event,
    reservation: {
      number: webhookEvent.reservation.number,
      externalId: webhookEvent.reservation.external_id,
      startsAt: webhookEvent.reservation.starts_at,
      endsAt: webhookEvent.reservation.ends_at,
    },
    testTaker: {
      studentId: webhookEvent.test_taker.student_id,
    },
  };
}
