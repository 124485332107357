import { ListParams, ListSharedResourceScope } from './list-params';
import { BasicAsset, BasicUser, User } from './shared';

export interface SurveyListParams extends Omit<ListParams, 'all' | 'archived'> {
  id?: number;
  name?: string;
  ownerEmail?: string;
}

export interface SurveyList {
  readonly count: number;
  readonly rows: Survey[];
}

export interface Survey {
  readonly id?: number;
  name?: string;
  provideTranslations?: boolean;
  assets?: BasicAsset[];
  sections?: SurveySection[];
  settings?: SurveySettings;
  ownerId?: number;
  owner?: User;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

export interface SurveySettings {
  emailOnReceivedResponse: boolean;
  mailingList: string[];
  emailRespondent: boolean;
}

export interface BasicSurvey {
  readonly id?: number;
  readonly name?: string;
}

export interface SurveySection {
  readonly id?: number;
  randomize?: boolean;
  sort?: number;
  questions?: SurveyQuestion[];
  surveyId?: number;
  survey?: BasicSurvey;
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

export interface SurveyQuestionAssignment {
  sort: number;
}

export enum SurveyQuestionType {
  multipleChoice = 'multipleChoice',
  checkBox = 'checkBox',
  shortAnswer = 'shortAnswer',
  longAnswer = 'longAnswer',
  dropdown = 'dropdown',
  netPromoterScore = 'nps',
}

export interface SurveyQuestionListParams extends Omit<ListParams, 'all' | 'archived'> {
  id?: number;
  type?: string;
  prompt?: string;
  scope?: ListSharedResourceScope;
  ownerEmail?: string;
}

export interface SurveyQuestionList {
  readonly count: number;
  readonly rows: SurveyQuestion[];
}

export interface SurveyQuestion {
  readonly id?: number;
  type?: string;
  prompt?: string;
  promptTranslations?: SurveyPromptTranslations;
  imageSrc?: string;
  randomize?: boolean;
  required?: boolean;
  ownerId?: number;
  owner?: User;
  answers?: SurveyAnswer[];
  surveys?: BasicSurvey[];
  sections?: SurveySection[];
  surveyQuestionAssignment?: SurveyQuestionAssignment;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

export interface SurveyAnswer {
  readonly id?: number;
  prompt?: string;
  promptTranslations?: SurveyPromptTranslations;
  imageSrc?: string;
  sort?: number;
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

export interface SurveyPromptTranslations {
  [key: string]: string;
}

export interface SurveyResultListParams extends Omit<ListParams, 'all' | 'archived'> {
  id?: number;
  startTime?: string; // This time refers to when the survey was taken
  endTime?: string; // This time refers to when the survey was taken
  eventStartTime?: string; // This time refers to when the training event that the survey is attached to started
  eventEndTime?: string; // This time refers to when the training event that the survey is attached to ended
  surveyId?: number;
  surveyName?: string;
  assetName?: string;
  userEmail?: string;
  scope?: ListSharedResourceScope;
}

export interface SurveyResultList {
  readonly count: number;
  readonly rows: SurveyResult[];
}

export interface SurveyResult {
  readonly id?: number;
  sections?: SurveyResultSection[];
  userId?: number;
  user?: BasicUser;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
  surveyId?: number;
  surveyName?: string;
  survey?: Survey;
  surveyProvideTranslations?: boolean;
  assetId?: number;
  asset?: BasicAsset;
}

export interface SurveyResultSection {
  readonly id?: number;
  randomize?: boolean;
  sort?: number;
  questions?: SurveyResultQuestion[];
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

export interface SurveyResultQuestion {
  readonly id?: number;
  answered?: boolean;
  answer?: string;
  answers?: SurveyResultAnswer[];
  sort?: number;
  surveyResultQuestionAssignment?: SurveyQuestionAssignment;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
  surveyQuestionId?: number;
  surveyQuestionType?: string;
  surveyQuestionPrompt?: string;
  surveyQuestionPromptTranslations?: SurveyPromptTranslations;
  surveyQuestionImageSrc?: string;
  surveyQuestionRandomize?: boolean;
  surveyQuestionRequired?: boolean;
}

export interface SurveyResultAnswer {
  readonly id?: number;
  selected?: boolean;
  sort?: number;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
  surveyAnswerId?: number;
  surveyAnswerPrompt?: string;
  surveyAnswerPromptTranslations?: SurveyPromptTranslations;
  surveyAnswerImageSrc?: string;
  surveyResultQuestionId?: number;
}
