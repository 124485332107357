<button
  mat-icon-button
  [matMenuTriggerFor]="notificationMenu"
  matTooltip="Notifications"
  matTooltipPosition="below"
  *ngIf="label === 'icon'"
>
  <mat-icon>notifications</mat-icon>
  <div class="dtu-badge" [class.dtu-active]="notificationList?.count"></div>
</button>
<button
  #notificationMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="notificationMenu"
  *ngIf="label === 'text'"
  (mouseenter)="notificationMenuTrigger.openMenu()"
>
  {{ notificationList?.count || 0 }} new notifications
</button>
<mat-menu class="dtu-menu dtu-notification-menu" #notificationMenu="matMenu" xPosition="before">
  <ng-container *ngFor="let notification of notificationList?.rows">
    <a
      *ngIf="notification.type === 'reminder'"
      class="dtu-menu-item dtu-notification-item"
      mat-menu-item
      routerLink="/account/events/{{ notification.data.registrationId }}"
    >
      <ng-container [ngTemplateOutlet]="templatedNotifications" [ngTemplateOutletContext]="{ notification: notification }"></ng-container>
    </a>
    <a
      *ngIf="notification.type === 'certification-renewal-reminder'"
      class="dtu-menu-item dtu-notification-item"
      mat-menu-item
      routerLink="/certification/{{ notification.data.certificationId }}"
    >
      <ng-container [ngTemplateOutlet]="templatedNotifications" [ngTemplateOutletContext]="{ notification: notification }"></ng-container>
    </a>
  </ng-container>
  <div class="dtu-menu-item dtu-notification-empty" disabled mat-menu-item *ngIf="!notificationList?.count">
    <mat-icon>notifications_none</mat-icon>
    <div class="dtu-notification-title">You're all caught up!</div>
    <div class="dtu-notification-message">Check again later for updates</div>
  </div>
</mat-menu>

<ng-template #templatedNotifications let-notification="notification">
  <div class="dtu-notification-content">
    <div class="dtu-notification-title">{{ notification.data.title }}</div>
    <div class="dtu-notification-message">{{ notification.data.message }}</div>
    <div class="dtu-notification-details">{{ notification.deliveryTime | date: 'longDate' }}</div>
  </div>
  <div class="dtu-notification-actions">
    <button
      class="dtu-small"
      (click)="$event.preventDefault(); $event.stopPropagation(); dismissNotification(notification)"
      mat-icon-button
      matTooltip="Dismiss"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</ng-template>
