/**
 * Video Duration Pipe
 *
 * This pipe transforms seconds into a video timestamp
 */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Class representing a video duration pipe
 */
@Pipe({ name: 'videoDuration' })
export class VideoDurationPipe implements PipeTransform {
  /**
   * Transform seconds into 00:00:00
   * @param duration - Duration in seconds
   * @returns A video timestamp
   */
  transform(duration: number): string {
    // Initialize time breakdown
    const numHours = Math.floor(duration / 3600);
    let numMinutes = Math.floor((duration - numHours * 3600) / 60);
    let numSeconds = Math.round(duration - numHours * 3600 - numMinutes * 60);

    // Fix time coming from video tag
    if (numSeconds === 60) {
      numSeconds = 0;
      numMinutes++;
    }

    // Initialize formatted video time breakdown
    let hours = '';
    let minutes = `${numMinutes}:`;
    let seconds = `${numSeconds}`;

    // Only add hour if available
    if (numHours > 0) {
      hours = `${numHours}:`;
    }

    // Add 0 for single digit minutes
    if (numHours > 0 && numMinutes < 10) {
      minutes = `0${numMinutes}:`;
    }

    // Add 0 for single digit seconds
    if (numSeconds < 10) {
      seconds = `0${numSeconds}`;
    }

    // Return parsed video time
    return hours + minutes + seconds;
  }
}
