/**
 * Fiscal Year Pipe
 *
 * This pipe transforms a date into the corresponding fiscal year and quarter
 */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Class representing a fiscal year pipe
 */
@Pipe({ name: 'fiscalYear' })
export class FiscalYearPipe implements PipeTransform {
  /**
   * Transform date into the corresponding fiscal year and quarter
   * @param date - Date to format
   * @returns A fiscal year and quarter string
   */
  transform(date: string): string {
    const currentDate = new Date(date);
    if (currentDate) {
      return `FY${this.getYear(currentDate)}Q${this.getQuarter(currentDate)}`;
    } else {
      return null;
    }
  }

  /**
   * Get the corresponding fiscal year
   * @param date - Date to format
   * @returns The matching fiscal year
   */
  getYear(date: Date): string {
    if (date.getMonth() >= 3) {
      const year = (date.getFullYear() + 1).toString();
      return year.substr(-2);
    } else {
      const year = date.getFullYear().toString();
      return year.substr(-2);
    }
  }

  /**
   * Get the corresponding quarter
   * @param date - Date to format
   * @returns The matching quarter
   */
  getQuarter(date: Date): number {
    const quarterList = [4, 1, 2, 3];
    return quarterList[Math.floor(date.getMonth() / 3)];
  }
}
