import { TagFilter } from './tag-filter';
import { ListOrder, ListParams } from './list-params';
import { RequiredKeys, ResourceList } from './shared';

class UpdateTagFilterSectionClass {
  name: string;
  tagFilters?: TagFilter[];
}

class CreateTagFilterSectionClass extends UpdateTagFilterSectionClass {
  sort: number;
}

class TagFilterSectionClass extends CreateTagFilterSectionClass {
  id: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateTagFilterSection extends UpdateTagFilterSectionClass {}
export interface CreateTagFilterSection extends CreateTagFilterSectionClass {}
export interface TagFilterSection extends TagFilterSectionClass {}

export const TAG_FILTER_SECTION_REQUIRED_PROPERTIES = Object.keys(new TagFilterSectionClass()) as Array<
  keyof RequiredKeys<TagFilterSection>
>;

export interface TagFilterSectionListParams extends Omit<ListParams, 'all' | 'attributes' | 'archive' | 'sort'> {
  order?: ListOrder;
  tagFilters?: boolean;
}

export interface TagFilterSectionGetParams {
  tagFilters?: boolean;
}

export type TagFilterSectionList = ResourceList<TagFilterSection>;
