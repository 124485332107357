import { ListParamsStrict, ListResourceScope } from './list-params';
import { Registration } from './registration';
import { Asset, Environment, ResourceList, User } from './shared';
import { Values } from './type-utils';

export interface UpdateTenantRequestBody {
  deletedAt?: string | Date | null;

  sharingType?: TenantSharingType;
  name?: string | null;
  status?: TenantStatus;
  startTime?: string | Date | null;
  endTime?: string | Date | null;
  accountUuid?: string;
  clusterUuid?: string | null;
  accessToken?: string | null;
  stage?: TenantStage;
  adaCreationTaskUuid?: string;
  creationRetries?: number;
  inviteRetries?: number;
  assetId?: Asset['id'];
  userId?: User['id'];
}

export interface CreateTenantRequestBody extends Omit<UpdateTenantRequestBody, 'deletedAt'> {
  sharingType: TenantSharingType;
  status: TenantStatus;
  startTime: string | Date | null;
  endTime: string | Date | null;
  accountUuid: string;
}

export interface Tenant extends UpdateTenantRequestBody {
  readonly id: number;
  readonly createdAt: string | Date;
  readonly updatedAt: string | Date;
  deletedAt?: string | Date | null;

  // Associations and Virtual fields
  asset?: Asset;
  environments?: Environment[];
  registrations?: Registration[];
  user?: User;
}

export type TenantConfiguration = Required<Pick<Tenant, 'sharingType' | 'startTime' | 'endTime' | 'accountUuid' | 'clusterUuid'>> &
  Pick<Tenant, 'status' | 'name'>;

export class Tenant implements Tenant {}

export type TenantList = ResourceList<Tenant>;

export interface TenantListParams extends ListParamsStrict {
  accountUuid?: string;
  complete?: boolean;
  name?: string;
  status?: TenantStatus[];
  startTime?: string | Date;
  endTime?: string | Date;
  sharingType?: TenantSharingType;
  creationRetries?: number;
  inviteRetries?: number;
  scope?: ListResourceScope;
  assetId?: Asset['id'];
  asset?: boolean;
  environments?: boolean;
  registrations?: boolean;
  userId?: User['id'];
  user?: boolean;
}

export const TenantSharingType = {
  NoSharing: 'noSharing',
  ShareTenantOnly: 'shareTenantOnly',
  ShareTenantAndVMs: 'shareTenantAndVMs',
} as const;
export type TenantSharingType = Values<typeof TenantSharingType>;

export const TenantStatus = {
  Future: 'future',
  Starting: 'starting',
  Running: 'running',
  Suspended: 'suspended',
  Stopped: 'stopped',
} as const;
export type TenantStatus = Values<typeof TenantStatus>;

export const TenantStage = {
  Development: 'dev',
  Hardening: 'hardening',
  Production: 'prod',
} as const;
export type TenantStage = Values<typeof TenantStage>;

export const TenantAccountUuidDefault = {
  Hardening: 'f02d2eb1-d9c3-4aa4-811f-87bf0f617f73',
  Production: '65458478-ab94-4669-b798-108436f8d65e',
};
export type TenantAccountUuidDefault = Values<typeof TenantAccountUuidDefault>;

export const TenantClusterUuidDefault = {
  Hardening: 'hrd-1311ec7f-d7c6-4834-9c41-c7ee756ed2a9',
  Production: 'prd-ae8e9ca0-63d7-4133-9f45-5cee130fb006',
};
export type TenantClusterUuidDefault = Values<typeof TenantClusterUuidDefault>;
