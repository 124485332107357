import { Values } from './type-utils';

export interface ListParams {
  attributes?: string;
  all?: boolean | string;
  archive?: boolean | string;
  page?: number;
  limit?: number;
  order?: string;
  sort?: string;
  scope?: ListSharedResourceScope;
}

export interface ListParamsStrict {
  attributes?: string[];
  all?: boolean;
  archive?: boolean;
  page?: number;
  limit?: number;
  order?: ListOrder;
  sort?: string;
}

export enum ListOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

export const ListResourceScope = {
  All: 'all',
  Archived: 'archived',
} as const;
export type ListResourceScope = Values<typeof ListResourceScope>;

export const ListSharedResourceScope = {
  ...ListResourceScope,
  Owned: 'owned',
  Shared: 'shared',
} as const;
export type ListSharedResourceScope = Values<typeof ListSharedResourceScope>;

export interface ParamValueCondition {
  value: string;
  condition: ParamCondition;
}

export const ParamCondition = {
  Eq: 'eq',
  Lt: 'lt',
  Lte: 'lte',
  Gt: 'gt',
  Gte: 'gte',
  Like: 'like',
  In: 'in',
} as const;
export type ParamCondition = Values<typeof ParamCondition>;
