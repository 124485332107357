import { ListOrder, ListParams } from './list-params';
import { ResourceList } from './shared';

export class AssetTypeFilter implements AssetTypeFilter {}

export interface AssetTypeFilter extends CreateAssetTypeFilter {
  id: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateAssetTypeFilter extends UpdateAssetTypeFilter {
  assetType: string;
}

export interface UpdateAssetTypeFilter {
  sort: number;
}

export interface AssetTypeFilterListParams extends Omit<ListParams, 'all' | 'attributes' | 'archive' | 'sort'> {
  order?: ListOrder;
}

export type AssetTypeFilterList = ResourceList<AssetTypeFilter>;
