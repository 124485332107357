import { ListParamsStrict } from './list-params';
import {
  Access,
  AssetListScope,
  AssetSkillLevel,
  AssetType,
  BasicAsset,
  BasicAssetActivity,
  BasicTag,
  InternalAssetCreateRequestBody,
  ResourceIdObject,
  ResourceList,
  StrictBasicUser,
  SupportedLanguage,
} from './shared';

export interface BaseTraining {
  access: Access;
  coverImage: string | null;
  createdAt: string;
  description: string | null;
  id: number;
  language: string | SupportedLanguage;
  name: string;
  objectives: string | null;
  prerequisites: string | null;
  sessionCount: number | null;
  skillLevel: AssetSkillLevel;
  tags: BasicTag[];
  thumbnail: string | null;
  type: AssetType.Training;
  updatedAt: string;
}

export interface Training extends BaseTraining {
  activity: BasicAssetActivity;
}

export interface InternalTraining extends BaseTraining {
  active: boolean;
  deletedAt: string | null;
  label: string | null;
  owner: StrictBasicUser | null;
  parents: BasicAsset[];
}

export type BaseBasicTraining = Pick<
  BaseTraining,
  'access' | 'createdAt' | 'description' | 'id' | 'language' | 'name' | 'sessionCount' | 'skillLevel' | 'thumbnail' | 'type' | 'updatedAt'
>;

export interface InternalBasicTraining extends BaseBasicTraining {
  active: InternalTraining['active'];
  deletedAt: InternalTraining['deletedAt'];
  owner: InternalTraining['owner'];
}

export type InternalTrainingList = ResourceList<Partial<InternalBasicTraining>>;

export interface BasicTraining extends BaseBasicTraining {}

export interface InternalListTrainingParams extends Omit<ListParamsStrict, 'all' | 'archived'> {
  active?: boolean;
  id?: number[];
  name?: string;
  ownerEmail?: string;
  scope?: AssetListScope;
}

export interface InternalCreateTrainingRequestBody
  extends Pick<
    InternalAssetCreateRequestBody,
    'access' | 'active' | 'description' | 'label' | 'language' | 'name' | 'objectives' | 'prerequisites' | 'skillLevel' | 'tags'
  > {
  parents?: ResourceIdObject[];
  sessionCount: number;
}

export interface InternalUpdateTrainingRequestBody extends Partial<InternalCreateTrainingRequestBody> {
  coverImage?: string | null;
  deletedAt?: null;
  thumbnail?: string | null;
}

export enum PartnerTrainingNames {
  Associate = 'Associate Certification Bootcamp',
  Professional = 'Professional Certification Bootcamp',
  Technical = 'Technical Sales Training Bootcamp',
}

export enum PartnerTrainingSchedulingMessages {
  AlreadyRegistered = 'alreadyRegistered',
  MissedPreviousSession = 'missedPreviousSession',
  PrerequisitesNotMet = 'prerequisitesNotMet',
  NotReadyToRenew = 'notReadyToRenew',
  Eligible = 'eligible',
}
