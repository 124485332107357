import { Component, OnInit } from '@angular/core';
import { FlagNames } from '@dtu/types';
import { AppService } from '../../app.service';

enum GlobalBannerProfile {
  Standard = 'standard',
  Warning = 'warning',
  Alert = 'alert',
}

interface GlobalBannerConfig {
  profile: GlobalBannerProfile;
  primaryText: string;
  secondaryText: string;
}

/**
 * Class representing the Global Banner component
 */
@Component({
  selector: 'dtu-global-banner',
  styleUrls: ['./global-banner.component.scss'],
  templateUrl: './global-banner.component.html',
})
export class GlobalBannerComponent implements OnInit {
  config: GlobalBannerConfig;
  isDismissed = false;

  GLOBAL_BANNER_PROFILE = GlobalBannerProfile;

  /**
   * Global Banner component
   * @param appService - Global application service
   */
  constructor(private readonly appService: AppService) {}

  /**
   * Initialize component
   */
  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize global banner configuration
   */
  async init(): Promise<void> {
    try {
      this.config = JSON.parse(await this.appService.flagClient.getStringValue(FlagNames.GlobalBannerConfig, ''));
    } catch (e) {}
  }
}
