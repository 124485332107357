import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
import { AuthApiService } from '../../shared/api/auth.service';
import { UserApiService } from '../../shared/api/users.service';

/**
 * Class representing the Global Nav component
 */
@Component({
  selector: 'dtu-global-nav',
  styleUrls: ['./global-nav.component.scss'],
  templateUrl: './global-nav.component.html',
})
export class GlobalNavComponent implements OnInit {
  hasAdminRole = false;

  menuItems = [
    { name: 'Dashboard', icon: 'dashboard', path: '/account/dashboard' },
    { name: 'On Demand', icon: 'ondemand_video', path: '/ondemand' },
    { name: 'Live Training', icon: 'event', path: '/live-training' },
    { name: 'Certification', icon: 'verified_user', path: '/certification' },
  ];
  supportUrl = environment.supportUrl;

  @ViewChildren(MatMenuTrigger) menuTriggers!: QueryList<MatMenuTrigger>;

  /**
   * Global Nav component
   * @param appService - Global application service
   * @param authApiService - Auth API service
   * @param router - Angular router
   * @param userApiService - User API service
   */
  constructor(
    public readonly appService: AppService,
    public readonly authApiService: AuthApiService,

    public readonly router: Router,
    private readonly userApiService: UserApiService,
  ) {}

  /**
   * Initialize component
   */
  ngOnInit(): void {
    this.hasAdminRole = Object.values(this.appService.account.permissions).some((permission) => permission);
  }

  /**
   * Close all menus on window:resize event
   */
  manageResize(): void {
    this.menuTriggers.forEach((trigger: MatMenuTrigger) => trigger.closeMenu());
  }

  /**
   * Navigate to FAQ
   */
  navigateToFAQ(): void {
    let content = 'general';
    if (this.router.url.includes('live-training')) {
      content = 'live-training';
    } else if (this.router.url.includes('certification')) {
      content = 'certification';
    }

    this.router.navigate(['/faq'], { queryParams: { content } });
  }

  /**
   * Reset user access
   */
  async resetUserAccess(): Promise<void> {
    try {
      await this.userApiService.resetUserAccess(this.appService.account.viewingAsUser.id);
      this.router.navigate([`/admin/users/${this.appService.account.viewingAsUser.id}/update`]);
    } catch (error) {
      this.appService.componentError(error, 'Unable to reset user access');
    }
  }

  /**
   * Sign out user
   */
  async signOut(): Promise<void> {
    try {
      const response = await this.authApiService.signOut();
      window.location.href = response.redirectUrl;
    } catch (error) {
      this.appService.componentError(error, 'Unable to sign out');
    }
  }
}
